const ENV = {
    dev: {
      OPENAI_API_KEY: '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'
    },
    prod: {
      OPENAI_API_KEY: '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'
    }
  };
  
  const getEnvVars = (env = 'dev') => {
    return ENV[env];
  };
  
  export default getEnvVars;
  