import React, { useState, useRef, useEffect, } from 'react';
import { View, Text, SafeAreaView, FlatList, StyleSheet, ScrollView, TouchableOpacity, Image, Animated  } from 'react-native';
import { Chip } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { Audio } from 'expo-av';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#ebebeb',

  },
  greeting: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: 30, // Define un margen superior para alejarlo del borde superior
    textAlign: 'center', // Centra el texto horizontalmente
    bottom: 10,
    padding:20
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    alignItems: 'center', // Centra el contenido verticalmente
    justifyContent: 'center', // Centra el contenido horizontalmente
  },
  chip: {
    margin: 8,
    backgroundColor: '#00bcd4',
    borderRadius: 50

  },

  parentContainer: {
    flex: 1,
    justifyContent: 'center', // Centra los hijos verticalmente
    alignItems: 'center',     // Centra los hijos horizontalmente
    marginTop: 30, // Da más espacio en la parte superior
  },
  instructionContainer: {
    padding: 15,
    borderRadius: 10,
    backgroundColor: 'white',
    marginVertical: 10,
    padding:20,
    width:'80%',

  },
  instructionText: {
    fontSize: 21,
    textAlign: 'center', // Centra el texto horizontalmente
  },
  buttonContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  customButton: {
    backgroundColor: '#77108991',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 50,
    width:'60%',
    marginTop: 10, // Da más espacio en la parte superior

  },
  buttonText: {
    color: 'white',
    fontSize: 21,
    textAlign: 'center', // Centra el texto horizontalmente

  },
  imageStyle: {
    width: 300,
    height: 200,
    alignSelf: 'center',
    borderRadius: 20,
    marginTop: 30, // Da más espacio en la parte superior
  },
  selectedButton: {
    backgroundColor: '#03a9f4',
    borderRadius:20,
    padding:10,
    marginBottom:20

    // ...otros estilos
  },

  unselectedButton: {
    borderRadius:20,
    padding:10,
    marginBottom:20,
    backgroundColor: '#36353542',
    // ...otros estilos
  },
  pawIcon: {
    marginTop: 0, // Da más espacio en la parte superior
    marginBottom: 30, // Espacio en la parte inferior
  },
  nightModeContainer: {
    backgroundColor: '#333',
  },
  nightModeText: {
    color: '#fff',
  },
  nightModeParentContainer: {
    backgroundColor: '#333',
  },
  nightModeInstructionContainer: {
    backgroundColor: '#555',
  },
  nightModeButtonContainer: {
    backgroundColor: '#333',
  },
  nightModeButton: {
    backgroundColor: '#03a9f4',
  },
  nightModeButtonText: {
    color: 'black',
  },
  nightModeChip: {
    backgroundColor: '#099bae',
  },
  nightModePawIcon: {
    color: '#009688',
  },
  nightModeSoundButton: {
    backgroundColor: '#004099',
  },
  nightModeSelectedButton: {
    backgroundColor: '#05cbe4',
  },
  nightModeUnselectedButton: {
    backgroundColor: '#d4dcdc61',
  },
  completionMessageContainer: {
    backgroundColor: 'lightgreen',
    
  },
  completionMessageText: {
    fontWeight: 'bold',
    
  },
  nightModeCompletionMessageContainer: {
    backgroundColor: '#333',
  },
  nightModeCompletionMessageText: {
    fontWeight: 'bold',
    color: '#05cbe4',  // Puedes elegir otro color si lo prefieres
  },
  animateImagen: {
    marginTop: 40,  // Ajusta este valor según tus necesidades
    // otros estilos...
  },
  generalImageStyle: {
    width: 150,  // Puedes ajustar estos valores
    height: 150, // según tus necesidades
    resizeMode: 'contain',
    marginBottom:60,
    marginTop:50,
  },

});


const soundMap = {
  sound1: require('./components/sound/Computer_Mouse_Click_01_Sound_Effect_Mp3_339.mp3'),
  sound2: require('./components/sound/008902849_prev.mp3'),
};

const soundImageMap = {
  sound1: {
    image: 'https://app.hundezonen.ch/docs/cliker1.png',
    pressedImage: 'https://app.hundezonen.ch/docs/clicker%20cxovpia%202.png',
    sound: soundMap.sound1
  },
  sound2: {
    image: 'https://app.hundezonen.ch/docs/silvato%20copia.png',
    pressedImage: 'https://app.hundezonen.ch/docs/silvajtxo%20coxpia.png',
    sound: soundMap.sound2
  },
};


  const ClickerTraining = ({ selectedLanguage,  isNightMode }) => {
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showClicker, setShowClicker] = useState(false);
  const [showGreeting, setShowGreeting] = useState(true);
  const [showNextButton, setShowNextButton] = useState(false);
  const [chatLog, setChatLog] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [isPressed, setIsPressed] = useState(false);
  const [sound, setSound] = useState(null);
  const [selectedSound, setSelectedSound] = useState('sound1');  // Establece el sonido por defecto
  const scrollViewRef = useRef();
  const scrollEndRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [lastNextButtonClick, setLastNextButtonClick] = useState(false);
  const [imageSource, setImageSource] = useState('https://app.hundezonen.ch/docs/cliker1.png');
  const scaleAnim = useRef(new Animated.Value(1)).current;




  const imageUrls = [
    
    'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',

    'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',

    'https://app.hundezonen.ch/docs/ddday.jpg',

    'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',

    'https://app.hundezonen.ch/docs/dog1%20copia.jpg',

   'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',

   'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',

   'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',

   'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',

   'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',

  ];


  const exercisesTranslations = {
    en: [
      {
        id: '1',
        name: 'Shake Hands',
        image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
        steps: [
          'Ask your dog to sit on a flat, comfortable surface.',
          'Approach him and extend your hand towards his paw, saying "Shake" in a clear and calm manner.',
          'If he moves his paw even slightly, immediately press the clicker.',
          'Reward the action with a treat and offer positive reinforcement like petting or verbal praise.',
          'Practice this in multiple sessions, progressively increasing the duration he should maintain his paw in your hand before receiving the reward.'
        ]
      },
      {
        id: '2',
        name: 'Sit',
        image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
        steps: [
          'Hold a treat close to your dog’s nose.',
          'Move your hand upward, allowing his head to follow the treat and causing his backside to lower.',
          'Once his backside touches the ground, press the clicker.',
          'Give him the treat and reward him with petting.',
          'Repeat these steps multiple times.'
        ]
      },
      {
        id: '3',
        name: 'Lie Down',
        image: 'https://app.hundezonen.ch/docs/portrait-little-cute-dog-calmly-lying-posing-isolated-white-studio-background.jpg',
        steps: [
          'Start with your dog in a seated position and ensure he is focused on you.',
          'Hold a treat in your hand and show it to your dog to get his attention.',
          'Gradually move your hand to the ground, ensuring his head follows the motion.',
          'As soon as he lies down on the ground, immediately press the clicker.',
          'Give him the treat and offer verbal praise and petting as positive reinforcement.',
          'Practice multiple times and in various settings until he can lie down on a simple cue or verbal command.'
        ]
      },
      {
        id: '4',
        name: 'Come When Called',
        image: 'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',
        steps: [
          'Ensure you are in a secure, controlled environment, such as a fenced garden or training area.',
          'Place your dog on a long leash to ensure control during the training.',
          'Walk away from him a few meters and call him by saying "Come" while gently pulling the leash.',
          'The moment he starts to move towards you, press the clicker to mark the behavior.',
          'When he reaches you, press the clicker again and reward him with treats and verbal praise.',
          'Practice this command in short but frequent sessions, gradually increasing the distance between you.'
        ]
      },
      {
        id: '5',
        name: 'Stay',
        image: 'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',
        steps: [
          'Signal your dog to sit or lie down in a quiet place.',
          'Maintain eye contact and say "Stay" while extending your hand in a "stop" gesture in front of his face.',
          'Step back and wait for a few seconds. Maintain your dog’s attention but avoid making any abrupt movements that might distract him.',
          'If he successfully holds the position, press the clicker and return to him.',
          'Reward with a treat and petting, praising his good behavior.',
          'Practice this exercise by gradually increasing the distance between you and your dog, as well as the duration he should remain still.'
        ]
      },
      {
        id: '6',
        name: 'Leave It',
        image: 'https://app.hundezonen.ch/docs/ddday.jpg',
        steps: [
          'Place a treat or favorite toy in front of your dog, but out of his reach.',
          'When he shows interest in the object, say "Leave it" in a clear and firm manner.',
          'Keep your hand closed around the treat and wait for him to divert his attention from the object.',
          'As soon as his attention moves away, even if it\'s just for a second, press the clicker.',
          'Offer a reward from your other hand, making sure it is better than the initial treat or toy.',
          'Repeat this process in different scenarios and with different objects to solidify the behavior.',
          'As he improves, increase the difficulty by allowing him to get closer to the object before giving the "Leave it" command.'
        ]
      },
      {
        id: '7',
        name: 'Spin',
        image: 'https://app.hundezonen.ch/docs/pomeranian-with-yellow-fur-lying-grass-it-s-back.jpg',
        steps: [
          'Stand in front of your dog and hold a treat close to his nose to capture his attention.',
          'Say "Spin" and slowly move the treat around his head, encouraging him to follow the scent.',
          'If he follows the scent and completes a full turn, immediately press the clicker.',
          'Give him the treat and offer him verbal praises and pets as positive reinforcement.',
          'Repeat the process multiple times across several training sessions, increasing the spin speed as he improves.',
          'Once he has mastered the command with the help of the treat, practice by giving only the verbal command to generalize the skill.'
        ]
      },
      {
        id: '8',
        name: 'Speak and Quiet',
        image: 'https://app.hundezonen.ch/docs/tired-after-good-walk-chocolate-labrador-retriever-dog-sits-yawn.jpg',
        steps: [
          'Find a stimulus that makes your dog bark, like a doorbell or toy.',
          'When he starts barking in response to the stimulus, say "Speak" and immediately press the clicker.',
          'Give him a treat and verbal praises.',
          'Wait for him to naturally stop barking or use a command like "Shh" to halt him.',
          'Once he has stopped, even if it\'s just for a moment, press the clicker and say "Quiet".',
          'Offer a treat and verbal praises as a reward.',
          'Practice this across different sessions, ensuring he can bark and stop on command before testing it in real-world situations.'
        ]
      },
      {
        id: '9',
        name: 'Jump',
        image: 'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',
        steps: [
          'Start with a low barrier you know your dog can easily cross.',
          'Lead him towards the barrier with a leash and place a treat or toy on the other side to motivate him.',
          'Give the command "Jump" while signaling him with your hand or body to encourage the jump.',
          'As soon as both front and back paws have crossed the barrier, press the clicker.',
          'Immediately reward him with the treat or toy and give him plenty of verbal praises and pets.',
          'Practice this multiple times, gradually increasing the barrier height and removing the need for a reward on the other side.'
        ]
      },
      {
        id: '10',
        name: 'Fetch',
        image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
        steps: [
          'Sit on the floor or a chair with your dog in front of you to minimize distractions.',
          'Show your dog a toy or treat and throw it a short distance while saying "Fetch".',
          'If he goes after the object, press the clicker to mark the behavior.',
          'When he returns with the object, give the command "Drop it" and press the clicker when he releases it.',
          'Reward him with a different treat or interactive play as a reward.',
          'Repeat the process, gradually increasing the distance. Make sure to practice both indoors and outdoors to generalize the behavior.'
        ]
      },
      {
        id: '11',
        name: 'Fetch and Deliver',
        image: 'https://app.hundezonen.ch/docs/cute-dog-playing-with-little-rubber-balls.jpg',
        steps: [
          'First teach your dog to "Drop It" and "Take" an object.',
          'Once that is clear, place an object on the floor.',
          'Say "Take" and point to the object.',
          'When it has it in its mouth, press the clicker.',
          'Say "Come" and press the clicker when it comes to you.',
          'Say "Drop It," and press the clicker when it drops the object.',
          'If done correctly, press the clicker and reward.',
          'Practice in short sessions and increase difficulty gradually.'
        ]
      },
      {
        id: '12',
        name: 'Walking Beside You',
        image: 'https://app.hundezonen.ch/docs/woman-walking-with-her-dog-walkway-park.jpg',
        steps: [
          'Start with a short leash in a controlled environment.',
          'Let the dog sniff and explore a bit.',
          'Begin walking slowly saying "Let\'s Go".',
          'Every time it looks at you or walks beside you, press the clicker.',
          'Stop and offer a reward.',
          'Continue practicing, increasing the duration and difficulty of the walk.',
          'If it gets ahead or lags behind, stop until it returns to the correct position, then press the clicker.',
          'Practice in different environments and levels of distraction.'
        ]
      },
      {
        id: '13',
        name: 'Going Up and Down Stairs',
        image: 'https://app.hundezonen.ch/docs/woman-walking-her-dog-city-while-it-rains.jpg',
        steps: [
          'Place your dog at the foot of a staircase and stand beside it.',
          'Say "Up" and start going up the stairs.',
          'If it follows and goes up at least one step, press the clicker.',
          'Reward with a treat and petting.',
          'To go down, use the command "Down" and do the same process but in reverse.',
          'Practice first with fewer stairs, then increase the number.',
          'If it shows resistance, do not force. Practice more on the lower steps until it gains confidence.'
        ]
      },
      {
        id: '14',
        name: 'Distance Commands',
        image: 'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',
        steps: [
          'Start by practicing basic commands like "Sit" and "Lie Down" but now adding distance between you and the dog.',
          'Take a step back and give the "Sit" command.',
          'If done correctly, press the clicker and return to give the reward.',
          'Gradually increase the distance between you and your dog.',
          'Make sure to practice this in a safe, distraction-free environment.',
          'Once mastered, you can practice in places with more distractions.'
        ]
      },
      {
        id: '15',
        name: 'Ignoring Distractions',
        image: 'https://app.hundezonen.ch/docs/old-person-with-their-pet-dog.jpg',
        steps: [
          'Place a treat or toy nearby but out of reach of your dog.',
          'Ask your dog to sit and stay.',
          'Walk toward the object and return.',
          'If it stays seated, press the clicker and offer a reward.',
          'Repeat, but this time touch the object before returning.',
          'Keep increasing the difficulty, eventually picking up the object and giving it only if it stays seated.',
          'Practice with different objects and levels of distraction.'
        ]
      },
      {
        id: '16',
        name: 'Object Name Recognition',
        image: 'https://app.hundezonen.ch/docs/ddday.jpg',
        steps: [
          'Start by introducing a single toy and giving it a specific name, like "Ball".',
          'Say "Where is the Ball?" and encourage your dog to pick it up.',
          'If done correctly, press the clicker and offer a reward.',
          'Repeat the process by adding more toys one by one, using different names.',
          'Mix up the toys and ask the dog to find a specific one ("Where is the Bone?", "Where is the Frisbee?").',
          'Press the clicker and offer a reward each time they find the correct object.',
          'Practice regularly to reinforce their recognition skills.'
        ]
      },
      {
        id: '17',
        name: 'Sit in Silence',
        image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
        steps: [
          'Begin in a distraction-free environment.',
          'Give the "Sit" command and wait for them to sit.',
          'Once seated, give the "Quiet" command while maintaining eye contact.',
          'If they remain seated and silent for a few seconds, press the clicker and reward them.',
          'Gradually increase the time they must remain silent before receiving the reward.',
          'Introduce distractions gradually and practice in different settings.'
        ]
      },
      {
        id: '18',
        name: 'Pick Up Leash',
        image: 'https://app.hundezonen.ch/docs/pet-owner-putting-dog-collar-his-pets-neck.jpg',
        steps: [
          'Drop the leash near the dog while saying the command "Leash".',
          'If they touch or pick it up, press the clicker and offer a reward.',
          'Practice this behavior multiple times.',
          'Increase the difficulty by asking them to bring the leash to you.',
          'If done successfully, press the clicker and offer a reward.',
          'Eventually, practice in different rooms to increase complexity.'
        ]
      },
      {
        id: '19',
        name: 'Paw Touch',
        image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
        steps: [
          'Present your open hand in front of your dog and say the command "Touch".',
          'Wait for them to touch your hand with their paw.',
          'Press the clicker and offer a reward instantly.',
          'Repeat the exercise multiple times until they consistently do it.',
          'Increase the difficulty by asking them to touch specific objects with their paw.',
          'If successful, press the clicker and offer a reward.'
        ]
      },
      {
        id: '20',
        name: 'Avoid Food on the Floor',
        image: 'https://app.hundezonen.ch/docs/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food.jpg',
        steps: [
          'Place a treat on the floor and say the command "No".',
          'If they avoid taking the treat, press the clicker and offer a reward from your hand.',
          'Practice this multiple times.',
          'Introduce new types of food and objects to generalize the behavior.',
          'If they avoid different foods and objects on the floor, press the clicker and offer a reward.',
          'Practice in different locations and with different kinds of food.'
        ]
      },{
        id: '21',
        name: 'Target Stick Training',
        image: 'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',
        steps: [
          'Introduce a stick with a pointing end in front of your dog.',
          'Say "Touch" while pointing to the target with the stick.',
          'When your dog touches the target with their nose or paw, press the clicker.',
          'Reward with a treat and verbal praise.',
          'Increase the difficulty by moving the target to different heights or directions.'
        ]
      },
      {
        id: '22',
        name: 'Zigzag Walking',
        image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
        steps: [
          'Start walking with your dog by your side on a short leash.',
          'Begin making zigzag movements, changing direction.',
          'When your dog follows you correctly in the turns, press the clicker.',
          'Reward with a treat and verbal praise.',
          'Practice in different locations with varying levels of distraction.'
        ]
      },
      {
        id: '23',
        name: 'Polite Greeting',
        image: 'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',
        steps: [
          'Teach your dog to greet people without jumping.',
          'When your dog is about to jump, say "No" and keep your hands closed.',
          'When your dog keeps all four paws on the ground, press the clicker.',
          'Reward with a treat and allow the person to pet the dog.',
          'Practice with friends and family to generalize the behavior.'
        ]
      },
      {
        id: '24',
        name: 'Search and Find',
        image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
        steps: [
          'Hide a treat or toy in a visible place for your dog at first.',
          'Say "Search" and point to where the object is.',
          'When your dog finds the object, press the clicker.',
          'Celebrate with a reward and verbal praise.',
          'Gradually increase difficulty by hiding the object in more challenging locations.'
        ]
      },
      {
        id: '25',
        name: 'Turn Off the Light',
        image: 'https://app.hundezonen.ch/docs/cute-purebred-puppy-sitting-looking-camera-generated-by-ai_188544-43521.jpg',
        steps: [
          'Train your dog to turn off a light switch that is accessible.',
          'Say "Light" and point to the switch.',
          'When your dog presses the switch with their nose or paw, press the clicker.',
          'Reward with a treat and verbal praise.',
          'Begin in a room with low light and increase difficulty gradually.'
        ]
      },
      {
        id: '26',
        name: 'Obstacle Course',
        image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
        steps: [
          'Set up obstacles like cones or small hurdles in your training area.',
          'Use commands like "Jump" or "Pass" while guiding your dog through the obstacles.',
          'Press the clicker when your dog successfully completes the obstacle course.',
          'Reward with a treat and verbal praise.',
          'Gradually increase the height and complexity of the obstacles.'
        ]
      }      
      
    ],


  es: [
    {
      id: '1',
      name: 'Dar la pata',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Solicita a tu perro que se siente en una superficie plana y cómoda.',
        'Acércate a él y extiende tu mano hacia su pata, diciendo "Pata" de forma clara y tranquila.',
        'Si responde moviendo su pata, aunque sea mínimamente, presiona el clicker de inmediato.',
        'Recompensa la acción con una golosina y refuerzo positivo como caricias o elogios verbales.',
        'Practica esto en múltiples sesiones, aumentando progresivamente el tiempo que debe mantener la pata en tu mano antes de recibir la recompensa.'
      ]
    },
    {
      id: '2',
      name: 'Sentarse',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Coloca una golosina cerca de la nariz de tu perro.',
        'Mueve tu mano hacia arriba, permitiendo que su cabeza siga la golosina y que su trasero se baje.',
        'Una vez que su trasero toque el suelo, presiona el clicker.',
        'Dale la golosina y felicítalo con caricias.',
        'Repite estos pasos varias veces.'
      ]
    },
    {
      id: '3',
      name: 'Tumbarse',
      image: 'https://app.hundezonen.ch/docs/portrait-little-cute-dog-calmly-lying-posing-isolated-white-studio-background.jpg',
      steps: [
        'Empieza con tu perro en una posición sentada y asegúrate de que esté enfocado en ti.',
        'Sujeta una golosina en tu mano y muéstrala a tu perro para captar su interés.',
        'Lleva tu mano al suelo de forma gradual, asegurándote de que su cabeza siga el movimiento.',
        'Cuando se tumbe en el suelo, presiona el clicker inmediatamente.',
        'Entrega la golosina y proporciona caricias y elogios verbales como refuerzo positivo.',
        'Practica varias veces y en diferentes entornos hasta que pueda tumbarse con una simple señal o comando verbal.'
      ]
    },
    {
      id: '4',
      name: 'Venir cuando se le llama',
      image: 'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',
      steps: [
        'Asegúrate de estar en un entorno seguro y controlado, como un jardín cerrado o un área de entrenamiento.',
        'Coloca a tu perro en una correa larga para asegurar su control durante el entrenamiento.',
        'Aléjate de él unos metros y llámalo diciendo "Ven" mientras haces un suave tirón de la correa.',
        'En el momento en que comienza a moverse hacia ti, presiona el clicker para marcar el comportamiento.',
        'Cuando llegue hasta ti, presiona el clicker nuevamente y recompénsalo con golosinas y elogios verbales.',
        'Practica este comando en sesiones cortas pero frecuentes, aumentando progresivamente la distancia entre ambos.'
      ]
    },
    {
      id: '5',
      name: 'Permanecer quieto',
      image: 'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',
      steps: [
        'Indica a tu perro que se siente o se tumbe en un lugar tranquilo.',
        'Mantén el contacto visual y di "Quédate" mientras extiendes tu mano en un gesto de "stop" frente a su cara.',
        'Da un paso atrás y espera unos segundos. Mantén la atención de tu perro pero evita hacer movimientos bruscos que puedan distraerlo.',
        'Si logra mantener la posición, presiona el clicker y regresa a él.',
        'Recompensa con una golosina y caricias, elogiando su buen comportamiento.',
        'Practica este ejercicio aumentando gradualmente la distancia entre tú y tu perro, así como el tiempo que debe permanecer quieto.'
      ]
    },
    {
      id: '6',
      name: 'Dejarlo',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Coloca una golosina o un juguete favorito frente a tu perro, pero fuera de su alcance.',
        'Cuando muestre interés en el objeto, di "Déjalo" de manera clara y firme.',
        'Mantén tu mano cerrada alrededor de la golosina y espera a que desvíe su atención del objeto.',
        'Una vez que su atención se aleje, incluso si es solo por un segundo, presiona el clicker.',
        'Ofrece una recompensa de tu otra mano, asegurándote de que sea mejor que la golosina o juguete inicial.',
        'Repite este proceso en diferentes escenarios y con diferentes objetos para solidificar el comportamiento.',
        'A medida que mejore, aumenta la dificultad permitiéndole acercarse más al objeto antes de dar la orden "Déjalo".'
      ]
    },
    {
      id: '7',
      name: 'Girar',
      image: 'https://app.hundezonen.ch/docs/pomeranian-with-yellow-fur-lying-grass-it-s-back.jpg',
      steps: [
        'Párate frente a tu perro y sostén una golosina cerca de su nariz para captar su atención.',
        'Di "Gira" y lentamente mueve la golosina alrededor de su cabeza, animándolo a seguir el aroma.',
        'Si sigue el olor y realiza un giro completo, inmediatamente presiona el clicker.',
        'Dale la golosina y ofrécele caricias y elogios verbales como refuerzo positivo.',
        'Repite el proceso varias veces durante varias sesiones de entrenamiento, aumentando la velocidad del giro a medida que mejora.',
        'Una vez que haya dominado el comando con la ayuda de la golosina, practica dando solo el comando verbal para generalizar la habilidad.'
      ]
    },
    {
      id: '8',
      name: 'Hablar y Callar',
      image: 'https://app.hundezonen.ch/docs/tired-after-good-walk-chocolate-labrador-retriever-dog-sits-yawn.jpg',
      steps: [
        'Encuentra un estímulo que haga que tu perro ladre, como un timbre de puerta o un juguete.',
        'Cuando empiece a ladrar en respuesta al estímulo, di "Habla" y presiona el clicker inmediatamente.',
        'Dale una recompensa y elogios verbales.',
        'Espera a que deje de ladrar naturalmente o usa un comando como "Shh" para detenerlo.',
        'Una vez que se haya detenido, incluso si es solo por un momento, presiona el clicker y di "Calla".',
        'Ofrece una golosina y elogios verbales como recompensa.',
        'Practica esto en diferentes sesiones, asegurándote de que pueda ladrar y detenerse a comando antes de probarlo en situaciones del mundo real.'
      ]
    },
    {
      id: '9',
      name: 'Saltar',
      image: 'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',
      steps: [
        'Comienza con una barrera baja que sepas que tu perro puede cruzar fácilmente.',
        'Llévalo hacia la barrera con una correa y coloca una golosina o juguete al otro lado para motivarlo.',
        'Dale el comando "Salta" mientras le haces una señal con la mano o el cuerpo para alentarlo a saltar.',
        'Tan pronto como las patas delanteras y traseras hayan cruzado la barrera, presiona el clicker.',
        'Recompénsalo inmediatamente con la golosina o el juguete y dale un montón de elogios verbales y caricias.',
        'Practica esto varias veces, aumentando gradualmente la altura de la barrera y eliminando la necesidad de una recompensa al otro lado.'
      ]
    },
    {
      id: '10',
      name: 'Buscar',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Siéntate en el suelo o en una silla con tu perro frente a ti para minimizar las distracciones.',
        'Muestra a tu perro un juguete o una golosina y lánzalo a una distancia corta mientras dices "Busca".',
        'Si va tras el objeto, presiona el clicker para marcar el comportamiento.',
        'Cuando regrese con el objeto, da el comando "Suelta" y presiona el clicker cuando lo suelte.',
        'Recompénsalo con una golosina diferente o un juego interactivo como recompensa.',
        'Repite el proceso, aumentando gradualmente la distancia. Asegúrate de practicar tanto en interiores como en exteriores para generalizar el comportamiento.'
      ]
    },
    {
      id: '11',
      name: 'Recoger y Entregar',
      image: 'https://app.hundezonen.ch/docs/cute-dog-playing-with-little-rubber-balls.jpg',
      steps: [
        'Primero enseña a tu perro a "Dejarlo" y "Tomar" un objeto.',
        'Una vez que tenga esto claro, coloca un objeto en el suelo.',
        'Di "Tomar" y señala el objeto.',
        'Cuando lo tenga en su boca, presiona el clicker.',
        'Di "Ven" y presiona el clicker cuando venga hacia ti.',
        'Di "Dejarlo", y presiona el clicker cuando suelte el objeto.',
        'Si lo hace correctamente, presiona el clicker y recompénsalo.',
        'Practica en sesiones cortas y aumenta la dificultad gradualmente.'
      ]
    },
    {
      id: '12',
      name: 'Caminar junto a ti',
      image: 'https://app.hundezonen.ch/docs/woman-walking-with-her-dog-walkway-park.jpg',
      steps: [
        'Comienza con una correa corta en un ambiente controlado.',
        'Deja que el perro huela y explore un poco.',
        'Comienza a caminar lentamente diciendo "Vamos".',
        'Cada vez que mire hacia ti o camine junto a ti, presiona el clicker.',
        'Detente y ofrécele una recompensa.',
        'Continúa practicando, aumentando la duración y la dificultad del paseo.',
        'Si se adelanta o se retrasa, detente hasta que vuelva a la posición correcta, luego presiona el clicker.',
        'Practica en diferentes entornos y niveles de distracción.'
      ]
    },
    {
      id: '13',
      name: 'Subir y Bajar Escaleras',
      image: 'https://app.hundezonen.ch/docs/woman-walking-her-dog-city-while-it-rains.jpg',
      steps: [
        'Coloca a tu perro al pie de una escalera y párate a su lado.',
        'Di "Sube" y comienza a subir las escaleras.',
        'Si sigue y sube al menos un escalón, presiona el clicker.',
        'Recompénsalo con una golosina y caricias.',
        'Para bajar, usa el comando "Baja" y haz el mismo proceso pero en sentido contrario.',
        'Practica primero con pocas escaleras, luego aumenta la cantidad.',
        'Si muestra resistencia, no fuerces. Practica más en los escalones inferiores hasta que gane confianza.'
      ]
    },
    {
      id: '14',
      name: 'Comandos de Distancia',
      image: 'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',
      steps: [
        'Comienza practicando los comandos básicos como "Sentarse" y "Tumbarse" pero ahora añadiendo distancia entre tú y el perro.',
        'Da un paso atrás y da la orden de "Sentarse".',
        'Si lo hace correctamente, presiona el clicker y regresa para darle la recompensa.',
        'Aumenta gradualmente la distancia entre tú y tu perro.',
        'Asegúrate de practicar esto en un ambiente seguro y sin distracciones.',
        'Una vez dominado, puedes practicar en lugares con más distracciones.'
      ]
    },
    {
      id: '15',
      name: 'Ignorar Distractions',
      image: 'https://app.hundezonen.ch/docs/old-person-with-their-pet-dog.jpg',
      steps: [
        'Coloca una golosina o juguete cerca pero fuera del alcance de tu perro.',
        'Pide a tu perro que se siente y permanezca quieto.',
        'Camina hacia el objeto y regresa.',
        'Si se mantiene sentado, presiona el clicker y ofrece una recompensa.',
        'Repite, pero esta vez toca el objeto antes de regresar.',
        'Continúa aumentando la dificultad, eventualmente recogiendo el objeto y dándoselo solo si se mantiene sentado.',
        'Practica con diferentes objetos y niveles de distracción.'
      ]
    },
    {
      id: '16',
      name: 'Reconocimiento de Nombres de Objetos',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Empieza presentando un solo juguete y dándole un nombre específico, como "Pelota".',
        'Dí "¿Dónde está la Pelota?" y alienta a tu perro a cogerla.',
        'Si lo hace correctamente, presiona el clicker y ofrece una recompensa.',
        'Repite el proceso agregando más juguetes uno a uno, usando diferentes nombres.',
        'Mezcla los juguetes y pide al perro que encuentre uno específico ("¿Dónde está el Hueso?", "¿Dónde está el Frisbee?").',
        'Presiona el clicker y ofrece una recompensa cada vez que encuentre el objeto correcto.',
        'Practica regularmente para reforzar su capacidad de reconocimiento.'
      ]
    },
    {
      id: '17',
      name: 'Sentado en Silencio',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Comienza en un entorno sin distracciones.',
        'Dale el comando "Siéntate" y espera a que se siente.',
        'Una vez sentado, dale el comando "Silencio" mientras mantienes contacto visual.',
        'Si permanece sentado y en silencio durante unos segundos, presiona el clicker y recompénsalo.',
        'Aumenta gradualmente el tiempo que debe permanecer en silencio antes de recibir la recompensa.',
        'Introduce distracciones de forma gradual y practica en diferentes entornos.'
      ]
    },
    {
      id: '18',
      name: 'Recoger la Correa',
      image: 'https://app.hundezonen.ch/docs/pet-owner-putting-dog-collar-his-pets-neck.jpg',
      steps: [
        'Deja caer la correa cerca del perro mientras dices el comando "Correa".',
        'Si la toca o la recoge, presiona el clicker y ofrece una recompensa.',
        'Practica este comportamiento varias veces.',
        'Aumenta la dificultad pidiendo que te traiga la correa.',
        'Si lo hace exitosamente, presiona el clicker y ofrece una recompensa.',
        'Eventualmente, practica en diferentes habitaciones para aumentar la complejidad.'
      ]
    },
    {
      id: '19',
      name: 'Tocar con la Pata',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Presenta tu mano abierta frente a tu perro y di el comando "Toca".',
        'Espera a que toque tu mano con su pata.',
        'Presiona el clicker y ofrece una recompensa al instante.',
        'Repite el ejercicio varias veces hasta que lo haga de forma consistente.',
        'Aumenta la dificultad pidiéndole que toque objetos específicos con la pata.',
        'Si lo logra, presiona el clicker y ofrece una recompensa.'
      ]
    },
    {
      id: '20',
      name: 'Evitar Comida en el Suelo',
      image: 'https://app.hundezonen.ch/docs/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food.jpg',
      steps: [
        'Coloca una golosina en el suelo y di el comando "No".',
        'Si evita tomar la golosina, presiona el clicker y ofrece una recompensa desde tu mano.',
        'Practica esto repetidas veces.',
        'Introduce nuevos tipos de comida y objetos para generalizar el comportamiento.',
        'Si evita comidas y objetos diferentes en el suelo, presiona el clicker y ofrece una recompensa.',
        'Practica en diferentes ubicaciones y con diferentes tipos de comida.'
      ]
    },
    {
      id: '21',
      name: 'Entrenamiento con Palo de Objetivo',
      image: 'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',
      steps: [
        'Presenta un palo con un extremo puntiagudo frente a tu perro.',
        'Di "Toca" mientras señalas el objetivo con el palo.',
        'Cuando tu perro toque el objetivo con su nariz o pata, presiona el clicker.',
        'Recompensa con una golosina y elogios verbales.',
        'Aumenta la dificultad moviendo el objetivo a diferentes alturas o direcciones.'
      ]
    },
    {
      id: '22',
      name: 'Caminata en Zigzag',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Comienza a caminar con tu perro a tu lado con una correa corta.',
        'Empieza a hacer movimientos en zigzag, cambiando de dirección.',
        'Cuando tu perro te siga correctamente en las vueltas, presiona el clicker.',
        'Recompensa con una golosina y elogios verbales.',
        'Practica en diferentes lugares con diferentes niveles de distracción.'
      ]
    },
    {
      id: '23',
      name: 'Saludo Educado',
      image: 'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',
      steps: [
        'Enseña a tu perro a saludar a las personas sin saltar.',
        'Cuando tu perro esté a punto de saltar, di "No" y mantén las manos cerradas.',
        'Cuando tu perro mantenga las cuatro patas en el suelo, presiona el clicker.',
        'Recompensa con una golosina y permite que la persona acaricie al perro.',
        'Practica con amigos y familiares para generalizar el comportamiento.'
      ]
    },
    {
      id: '24',
      name: 'Búsqueda y Encuentro',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Esconde una golosina o juguete en un lugar visible para tu perro al principio.',
        'Di "Búsqueda" y señala dónde está el objeto.',
        'Cuando tu perro encuentre el objeto, presiona el clicker.',
        'Celebra con una recompensa y elogios verbales.',
        'Aumenta gradualmente la dificultad escondiendo el objeto en lugares más desafiantes.'
      ]
    },
    {
      id: '25',
      name: 'Apagar la Luz',
      image: 'https://app.hundezonen.ch/docs/cute-purebred-puppy-sitting-looking-camera-generated-by-ai_188544-43521.jpg',
      steps: [
        'Entrena a tu perro para que apague un interruptor de luz que sea accesible.',
        'Di "Luz" y señala el interruptor.',
        'Cuando tu perro presione el interruptor con su nariz o pata, presiona el clicker.',
        'Recompensa con una golosina y elogios verbales.',
        'Comienza en una habitación con poca luz y aumenta gradualmente la dificultad.'
      ]
    },
    {
      id: '26',
      name: 'Curso de Obstáculos',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Configura obstáculos como conos o pequeñas vallas en tu área de entrenamiento.',
        'Utiliza comandos como "Salta" o "Pasa" mientras guías a tu perro a través de los obstáculos.',
        'Presiona el clicker cuando tu perro complete el curso de obstáculos con éxito.',
        'Recompensa con una golosina y elogios verbales.',
        'Aumenta gradualmente la altura y la complejidad de los obstáculos.'
      ]
    }     
  ],
  de: [
    {
      id: '1',
      name: 'Pfote geben',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Bitte deinen Hund, sich auf einer flachen, bequemen Oberfläche hinzusetzen.',
        'Nähere dich ihm und strecke deine Hand zu seiner Pfote aus, während du "Pfote" klar und ruhig sagst.',
        'Reagiert er, indem er seine Pfote bewegt, auch nur minimal, drücke sofort den Clicker.',
        'Belohne die Aktion mit einem Leckerli und positiver Verstärkung wie Streicheleinheiten oder verbalen Lob.',
        'Übe dies in mehreren Sitzungen und erhöhe schrittweise die Zeit, die er die Pfote in deiner Hand halten muss, bevor er die Belohnung erhält.'
      ]
    },
    {
      id: '2',
      name: 'Sitz',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Halte ein Leckerli nahe an die Nase deines Hundes.',
        'Bewege deine Hand nach oben, sodass sein Kopf dem Leckerli folgt und sein Hinterteil sich senkt.',
        'Sobald sein Hinterteil den Boden berührt, drücke den Clicker.',
        'Gib ihm das Leckerli und streichle ihn.',
        'Wiederhole diese Schritte mehrmals.'
      ]
    },
    {
      id: '3',
      name: 'Platz',
      image: 'https://app.hundezonen.ch/docs/portrait-little-cute-dog-calmly-lying-posing-isolated-white-studio-background.jpg',
      steps: [
        'Beginne damit, dass dein Hund sitzt und sicherstelle, dass er auf dich fokussiert ist.',
        'Halte ein Leckerli in der Hand und zeige es deinem Hund, um sein Interesse zu wecken.',
        'Führe deine Hand langsam zum Boden und sorge dafür, dass sein Kopf der Bewegung folgt.',
        'Wenn er sich hinlegt, drücke den Clicker sofort.',
        'Gib ihm das Leckerli und streichele und lobe ihn als positive Verstärkung.',
        'Übe dies mehrmals und in verschiedenen Umgebungen, bis er sich mit einem einfachen Signal oder Sprachbefehl hinlegt.'
      ]
    },
    {
      id: '4',
      name: 'Komm',
      image: 'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',
      steps: [
        'Stelle sicher, dass du dich in einer sicheren und kontrollierten Umgebung befindest, wie zum Beispiel einem geschlossenen Garten oder einem Übungsplatz.',
        'Lege deinem Hund eine lange Leine an, um die Kontrolle während des Trainings sicherzustellen.',
        'Entferne dich einige Meter von ihm und rufe "Komm", während du leicht an der Leine ziehst.',
        'Sobald er anfängt, sich dir zu nähern, drücke den Clicker, um das Verhalten zu markieren.',
        'Wenn er bei dir ankommt, drücke den Clicker erneut und belohne ihn mit Leckerlis und verbalen Lob.',
        'Übe diesen Befehl in kurzen, aber häufigen Sitzungen und erhöhe schrittweise den Abstand zwischen euch beiden.'
      ]
    },
    {
      id: '5',
      name: 'Bleib',
      image: 'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',
      steps: [
        'Fordere deinen Hund auf, sich an einem ruhigen Ort hinzusetzen oder hinzulegen.',
        'Halte Blickkontakt und sage "Bleib", während du deine Hand in einer "Stopp"-Geste vor seinem Gesicht ausstreckst.',
        'Mache einen Schritt zurück und warte einige Sekunden. Halte die Aufmerksamkeit deines Hundes, aber vermeide plötzliche Bewegungen, die ihn ablenken könnten.',
        'Wenn er die Position halten kann, drücke den Clicker und kehre zu ihm zurück.',
        'Belohne ihn mit einem Leckerli und Streicheleinheiten und lobe sein gutes Verhalten.',
        'Übe diese Übung, indem du schrittweise die Entfernung zwischen dir und deinem Hund sowie die Zeit, die er ruhig bleiben muss, erhöhst.'
      ]
    },
    {
      id: '6',
      name: 'Lass es',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Platziere einen Leckerbissen oder ein Lieblingsspielzeug vor deinem Hund, aber außerhalb seiner Reichweite.',
        'Wenn er Interesse an dem Objekt zeigt, sage "Lass es" in einer klaren und festen Weise.',
        'Halte deine Hand geschlossen um den Leckerbissen und warte, bis er seine Aufmerksamkeit von dem Objekt abwendet.',
        'Sobald seine Aufmerksamkeit abgelenkt ist, auch wenn es nur für eine Sekunde ist, drücke den Clicker.',
        'Biete eine Belohnung aus deiner anderen Hand an und stelle sicher, dass sie besser ist als der ursprüngliche Leckerbissen oder das Spielzeug.',
        'Wiederhole diesen Prozess in verschiedenen Szenarien und mit verschiedenen Objekten, um das Verhalten zu festigen.',
        'Erhöhe den Schwierigkeitsgrad, indem du ihm erlaubst, dem Objekt näher zu kommen, bevor du den Befehl "Lass es" gibst.'
      ]
    },
    {
      id: '7',
      name: 'Drehen',
      image: 'https://app.hundezonen.ch/docs/pomeranian-with-yellow-fur-lying-grass-it-s-back.jpg',
      steps: [
        'Stelle dich vor deinen Hund und halte einen Leckerbissen nahe an seine Nase, um seine Aufmerksamkeit zu bekommen.',
        'Sage "Drehen" und bewege den Leckerbissen langsam um seinen Kopf herum, um ihn zum Folgen des Duftes zu animieren.',
        'Wenn er dem Duft folgt und eine vollständige Drehung macht, drücke sofort den Clicker.',
        'Gib ihm den Leckerbissen und biete verbale Lob und Streicheleinheiten als positive Verstärkung an.',
        'Wiederhole den Vorgang mehrmals in verschiedenen Trainingseinheiten und erhöhe die Geschwindigkeit der Drehung, wenn er sich verbessert.',
        'Sobald er den Befehl mit Hilfe des Leckerbissens gemeistert hat, übe nur den verbalen Befehl aus, um die Fähigkeit zu generalisieren.'
      ]
    },
    {
      id: '8',
      name: 'Sprechen und Schweigen',
      image: 'https://app.hundezonen.ch/docs/tired-after-good-walk-chocolate-labrador-retriever-dog-sits-yawn.jpg',
      steps: [
        'Finde einen Reiz, der deinen Hund zum Bellen bringt, wie z.B. eine Türklingel oder ein Spielzeug.',
        'Wenn er als Reaktion auf den Reiz zu bellen beginnt, sage "Sprechen" und drücke sofort den Clicker.',
        'Gib ihm eine Belohnung und verbales Lob.',
        'Warte, bis er von selbst aufhört zu bellen oder benutze einen Befehl wie "Schhh" um ihn zu stoppen.',
        'Sobald er aufgehört hat, auch wenn es nur für einen Moment ist, drücke den Clicker und sage "Schweigen".',
        'Biete einen Leckerbissen und verbales Lob als Belohnung an.',
        'Übe das in verschiedenen Sitzungen, und stelle sicher, dass er auf Kommando bellen und aufhören kann, bevor du es in realen Situationen ausprobierst.'
      ]
    },
    {
      id: '9',
      name: 'Springen',
      image: 'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',
      steps: [
        'Beginne mit einer niedrigen Barriere, von der du weißt, dass dein Hund sie leicht überqueren kann.',
        'Führe ihn mit einer Leine zur Barriere und platziere einen Leckerbissen oder ein Spielzeug auf der anderen Seite, um ihn zu motivieren.',
        'Gib den Befehl "Springen", während du ihm ein Hand- oder Körpersignal gibst, um ihn zum Springen zu ermutigen.',
        'Sobald beide Vorder- und Hinterbeine die Barriere überquert haben, drücke den Clicker.',
        'Belohne ihn sofort mit dem Leckerbissen oder dem Spielzeug und biete viel verbales Lob und Streicheleinheiten an.',
        'Übe das mehrmals und erhöhe die Höhe der Barriere schrittweise und entferne die Notwendigkeit einer Belohnung auf der anderen Seite.'
      ]
    },
    {
      id: '10',
      name: 'Suchen',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Setze dich auf den Boden oder auf einen Stuhl mit deinem Hund vor dir, um Ablenkungen zu minimieren.',
        'Zeige deinem Hund ein Spielzeug oder einen Leckerbissen und wirf es in kurzer Entfernung, während du "Suchen" sagst.',
        'Wenn er dem Objekt nachgeht, drücke den Clicker, um das Verhalten zu markieren.',
        'Wenn er mit dem Objekt zurückkommt, gib den Befehl "Loslassen" und drücke den Clicker, wenn er es loslässt.',
        'Belohne ihn mit einem anderen Leckerbissen oder einem interaktiven Spiel als Belohnung.',
        'Wiederhole den Vorgang und erhöhe die Entfernung schrittweise. Übe sowohl drinnen als auch draußen, um das Verhalten zu generalisieren.'
      ]
    },
    
    {
      id: '11',
      name: 'Holen und Bringen',
      image: 'https://app.hundezonen.ch/docs/cute-dog-playing-with-little-rubber-balls.jpg',
      steps: [
        'Bringe deinem Hund zuerst bei, ein Objekt "Fallen zu lassen" und "Zu nehmen".',
        'Lege ein Objekt auf den Boden, sobald das klar ist.',
        'Sage "Nimm" und zeige auf das Objekt.',
        'Wenn er es im Maul hat, drücke den Clicker.',
        'Sage "Komm" und drücke den Clicker, wenn er zu dir kommt.',
        'Sage "Lass es fallen" und drücke den Clicker, wenn er das Objekt fallen lässt.',
        'Wenn es richtig gemacht wurde, drücke den Clicker und belohne ihn.',
        'Übe in kurzen Sitzungen und erhöhe die Schwierigkeit allmählich.'
      ]
    },
    {
      id: '12',
      name: 'Neben dir laufen',
      image: 'https://app.hundezonen.ch/docs/woman-walking-with-her-dog-walkway-park.jpg',
      steps: [
        'Beginne mit einer kurzen Leine in einer kontrollierten Umgebung.',
        'Lass den Hund ein wenig schnüffeln und erkunden.',
        'Beginne langsam zu laufen und sage "Los".',
        'Jedes Mal, wenn er dich ansieht oder neben dir läuft, drücke den Clicker.',
        'Halte an und biete eine Belohnung an.',
        'Fahre fort mit dem Üben, und erhöhe die Dauer und den Schwierigkeitsgrad des Spaziergangs.',
        'Wenn er vorausläuft oder zurückbleibt, halte an, bis er in die richtige Position zurückkehrt, dann drücke den Clicker.',
        'Übe in verschiedenen Umgebungen und Ablenkungsstufen.'
      ]
    },
    {
      id: '13',
      name: 'Treppen rauf und runter',
      image: 'https://app.hundezonen.ch/docs/woman-walking-her-dog-city-while-it-rains.jpg',
      steps: [
        'Stelle deinen Hund am Fuß einer Treppe ab und stelle dich daneben.',
        'Sage "Hoch" und fange an, die Treppen hinaufzugehen.',
        'Wenn er folgt und mindestens eine Stufe hochgeht, drücke den Clicker.',
        'Belohne ihn mit einem Leckerli und Streicheleinheiten.',
        'Um hinunterzugehen, verwende das Kommando "Runter" und mache denselben Prozess, aber in umgekehrter Reihenfolge.',
        'Übe zuerst mit weniger Stufen, dann erhöhe die Anzahl.',
        'Wenn er Widerstand zeigt, zwinge ihn nicht. Übe mehr auf den unteren Stufen, bis er Vertrauen gewinnt.'
      ]
    },
    {
      id: '14',
      name: 'Befehle aus der Entfernung',
      image: 'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',
      steps: [
        'Beginne mit dem Üben von Grundbefehlen wie "Sitz" und "Platz", aber jetzt mit Abstand zwischen dir und dem Hund.',
        'Mache einen Schritt zurück und gebe das Kommando "Sitz".',
        'Wenn es richtig gemacht wurde, drücke den Clicker und kehre zurück, um die Belohnung zu geben.',
        'Erhöhe allmählich den Abstand zwischen dir und deinem Hund.',
        'Stelle sicher, dass du dies in einer sicheren, ablenkungsfreien Umgebung übst.',
        'Sobald er es beherrscht, kannst du an Orten mit mehr Ablenkungen üben.'
      ]
    },
    {
      id: '15',
      name: 'Ablenkungen ignorieren',
      image: 'https://app.hundezonen.ch/docs/old-person-with-their-pet-dog.jpg',
      steps: [
        'Lege ein Leckerli oder Spielzeug in der Nähe, aber außerhalb der Reichweite deines Hundes.',
        'Fordere deinen Hund auf, sich hinzusetzen und still zu bleiben.',
        'Gehe auf das Objekt zu und kehre zurück.',
        'Wenn er sitzen bleibt, drücke den Clicker und biete eine Belohnung an.',
        'Wiederhole es, aber berühre dieses Mal das Objekt, bevor du zurückkehrst.',
        'Erhöhe weiter die Schwierigkeit, und hebe schließlich das Objekt auf und gib es ihm nur, wenn er sitzen bleibt.',
        'Übe mit verschiedenen Objekten und Ablenkungsstufen.'
      ]
    },
    {
      id: '16',
      name: 'Objektnamenerkennung',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Beginnen Sie damit, ein einzelnes Spielzeug vorzustellen und ihm einen spezifischen Namen wie "Ball" zu geben.',
        'Sagen Sie "Wo ist der Ball?" und ermutigen Sie Ihren Hund, ihn aufzuheben.',
        'Wenn es richtig gemacht wird, drücken Sie den Clicker und bieten Sie eine Belohnung an.',
        'Wiederholen Sie den Vorgang, indem Sie nach und nach mehr Spielzeuge hinzufügen und verschiedene Namen verwenden.',
        'Mischen Sie die Spielzeuge und bitten Sie den Hund, ein bestimmtes zu finden ("Wo ist der Knochen?", "Wo ist die Frisbee?").',
        'Drücken Sie den Clicker und bieten Sie bei jedem korrekten Fund eine Belohnung an.',
        'Trainieren Sie regelmäßig, um ihre Erkennungsfähigkeiten zu stärken.'
      ]
    },
    {
      id: '17',
      name: 'Im Stillstand sitzen',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Beginnen Sie in einer ablenkungsfreien Umgebung.',
        'Geben Sie das Kommando "Sitz" und warten Sie, bis der Hund sitzt.',
        'Wenn er sitzt, geben Sie das Kommando "Ruhig" ab und halten Sie den Augenkontakt.',
        'Wenn er einige Sekunden lang still sitzen bleibt, drücken Sie den Clicker und belohnen Sie ihn.',
        'Erhöhen Sie allmählich die Zeit, die er ruhig bleiben muss, um die Belohnung zu erhalten.',
        'Führen Sie nach und nach Ablenkungen ein und üben Sie in verschiedenen Umgebungen.'
      ]
    },
    {
      id: '18',
      name: 'Leine aufheben',
      image: 'https://app.hundezonen.ch/docs/pet-owner-putting-dog-collar-his-pets-neck.jpg',
      steps: [
        'Lassen Sie die Leine in der Nähe des Hundes fallen und sagen Sie das Kommando "Leine".',
        'Wenn er sie berührt oder aufnimmt, drücken Sie den Clicker und bieten Sie eine Belohnung an.',
        'Üben Sie dieses Verhalten mehrmals.',
        'Erhöhen Sie den Schwierigkeitsgrad, indem Sie ihn bitten, Ihnen die Leine zu bringen.',
        'Wenn dies erfolgreich ist, drücken Sie den Clicker und bieten Sie eine Belohnung an.',
        'Üben Sie schließlich in verschiedenen Räumen, um die Komplexität zu erhöhen.'
      ]
    },
    {
      id: '19',
      name: 'Pfotenberührung',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Halten Sie Ihre offene Hand vor Ihren Hund und sagen Sie das Kommando "Berühren".',
        'Warten Sie, bis er Ihre Hand mit seiner Pfote berührt.',
        'Drücken Sie den Clicker und bieten Sie sofort eine Belohnung an.',
        'Wiederholen Sie die Übung mehrmals, bis er es konsequent macht.',
        'Erhöhen Sie den Schwierigkeitsgrad, indem Sie ihn bitten, bestimmte Objekte mit seiner Pfote zu berühren.',
        'Wenn dies erfolgreich ist, drücken Sie den Clicker und bieten Sie eine Belohnung an.'
      ]
    },
    {
      id: '20',
      name: 'Essen auf dem Boden vermeiden',
      image: 'https://app.hundezonen.ch/docs/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food.jpg',
      steps: [
        'Legen Sie ein Leckerli auf den Boden und sagen Sie das Kommando "Nein".',
        'Wenn er das Leckerli nicht nimmt, drücken Sie den Clicker und bieten Sie eine Belohnung aus Ihrer Hand an.',
        'Üben Sie dies mehrmals.',
        'Führen Sie neue Arten von Lebensmitteln und Objekten ein, um das Verhalten zu verallgemeinern.',
        'Wenn er verschiedene Lebensmittel und Objekte auf dem Boden meidet, drücken Sie den Clicker und bieten Sie eine Belohnung an.',
        'Üben Sie an verschiedenen Orten und mit verschiedenen Arten von Lebensmitteln.'
      ]
    },
    {
      id: '21',
      name: 'Zielstock-Training',
      image: 'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',
      steps: [
        'Stellen Sie Ihrem Hund einen Stock mit einer zeigenden Spitze vor.',
        'Sagen Sie "Berühren", während Sie auf das Ziel mit dem Stock zeigen.',
        'Wenn Ihr Hund das Ziel mit seiner Nase oder Pfote berührt, drücken Sie den Clicker.',
        'Belohnen Sie mit einem Leckerli und verbalem Lob.',
        'Erhöhen Sie die Schwierigkeit, indem Sie das Ziel in verschiedene Höhen oder Richtungen bewegen.'
      ]
    },
    {
      id: '22',
      name: 'Zickzack-Gehen',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Beginnen Sie mit Ihrem Hund an Ihrer Seite an der kurzen Leine zu gehen.',
        'Beginnen Sie, Zickzack-Bewegungen zu machen und die Richtung zu ändern.',
        'Wenn Ihr Hund Ihnen korrekt in den Wendungen folgt, drücken Sie den Clicker.',
        'Belohnen Sie mit einem Leckerli und verbalem Lob.',
        'Üben Sie an verschiedenen Orten mit unterschiedlichen Ablenkungsgraden.'
      ]
    },
    {
      id: '23',
      name: 'Höfliche Begrüßung',
      image: 'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',
      steps: [
        'Lehren Sie Ihrem Hund, Menschen ohne Springen zu begrüßen.',
        'Wenn Ihr Hund kurz davor ist zu springen, sagen Sie "Nein" und halten Sie die Hände geschlossen.',
        'Wenn Ihr Hund alle vier Pfoten auf dem Boden behält, drücken Sie den Clicker.',
        'Belohnen Sie mit einem Leckerli und erlauben Sie der Person, den Hund zu streicheln.',
        'Üben Sie mit Freunden und Familie, um das Verhalten zu verallgemeinern.'
      ]
    },
    {
      id: '24',
      name: 'Suchen und Finden',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Verstecken Sie zuerst ein Leckerli oder ein Spielzeug an einem sichtbaren Ort für Ihren Hund.',
        'Sagen Sie "Suchen" und zeigen Sie auf die Stelle, wo sich der Gegenstand befindet.',
        'Wenn Ihr Hund den Gegenstand findet, drücken Sie den Clicker.',
        'Feiern Sie mit einer Belohnung und verbalem Lob.',
        'Erhöhen Sie die Schwierigkeit allmählich, indem Sie den Gegenstand an schwierigeren Stellen verstecken.'
      ]
    },
    {
      id: '25',
      name: 'Licht ausschalten',
      image: 'https://app.hundezonen.ch/docs/cute-purebred-puppy-sitting-looking-camera-generated-by-ai_188544-43521.jpg',
      steps: [
        'Trainieren Sie Ihren Hund, um einen erreichbaren Lichtschalter auszuschalten.',
        'Sagen Sie "Licht" und zeigen Sie auf den Schalter.',
        'Wenn Ihr Hund den Schalter mit seiner Nase oder Pfote drückt, drücken Sie den Clicker.',
        'Belohnen Sie mit einem Leckerli und verbalem Lob.',
        'Beginnen Sie in einem Raum mit wenig Licht und erhöhen Sie die Schwierigkeit allmählich.'
      ]
    },
    {
      id: '26',
      name: 'Hindernisparcours',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Richten Sie Hindernisse wie Kegel oder kleine Hürden in Ihrem Trainingsbereich ein.',
        'Verwenden Sie Befehle wie "Spring" oder "Pass", während Sie Ihren Hund durch die Hindernisse führen.',
        'Drücken Sie den Clicker, wenn Ihr Hund den Hindernisparcours erfolgreich abschließt.',
        'Belohnen Sie mit einem Leckerli und verbalem Lob.',
        'Erhöhen Sie allmählich die Höhe und Komplexität der Hindernisse.'
      ]
    }    

  ],

  fr: [
    {
      id: '1',
      name: 'Serrer la patte',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Demande à ton chien de s’asseoir sur une surface plate et confortable.',
        'Approche-le et tends la main vers sa patte, en disant "Patte" d’une manière claire et calme.',
        'S’il réagit en bougeant sa patte, même légèrement, presse immédiatement le clicker.',
        'Récompense cette action avec une friandise et des renforcements positifs tels que des caresses ou des éloges.',
        'Pratique cela en plusieurs sessions en augmentant progressivement le temps qu’il doit maintenir sa patte dans ta main avant de recevoir la récompense.'
      ]
    },
    {
      id: '2',
      name: 'S’asseoir',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Place une friandise près du nez de ton chien.',
        'Déplace ta main vers le haut, permettant à sa tête de suivre la friandise et à son arrière-train de s’abaisser.',
        'Dès que ses fesses touchent le sol, presse le clicker.',
        'Donne-lui la friandise et félicite-le avec des caresses.',
        'Répète ces étapes plusieurs fois.'
      ]
    },
    {
      id: '3',
      name: 'Se coucher',
      image: 'https://app.hundezonen.ch/docs/portrait-little-cute-dog-calmly-lying-posing-isolated-white-studio-background.jpg',
      steps: [
        'Commence avec ton chien en position assise et assure-toi qu’il se concentre sur toi.',
        'Tiens une friandise dans ta main et montre-la à ton chien pour capter son intérêt.',
        'Abaisse ta main vers le sol, en l’incitant à se coucher.',
        'Dès qu’il est couché, presse immédiatement le clicker.',
        'Donne-lui la friandise et offre des caresses et des éloges comme renforcement positif.',
        'Pratique plusieurs fois et dans différents environnements jusqu’à ce qu’il puisse se coucher avec un simple signal ou ordre vocal.'
      ]
    },
    {
      id: '4',
      name: 'Venir',
      image: 'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',
      steps: [
        'Assure-toi d’être dans un environnement sécurisé et contrôlé, comme un jardin clôturé ou une aire d’entraînement.',
        'Mets à ton chien une laisse longue pour assurer son contrôle pendant l’entraînement.',
        'Éloigne-toi de lui de quelques mètres et appelle-le en disant "Viens" tout en tirant doucement sur la laisse.',
        'Dès qu’il commence à se rapprocher de toi, presse le clicker pour marquer le comportement.',
        'Lorsqu’il arrive jusqu’à toi, presse de nouveau le clicker et récompense-le avec des friandises et des éloges.',
        'Pratique ce commandement lors de sessions courtes mais fréquentes, en augmentant progressivement la distance entre vous deux.'
      ]
    },
    {
      id: '5',
      name: 'Rester',
      image: 'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',
      steps: [
        'Demande à ton chien de s’asseoir ou de se coucher dans un endroit calme.',
        'Maintiens un contact visuel et dis "Reste" tout en étendant ta main dans un geste d’arrêt devant son visage.',
        'Recule d’un pas et attends quelques secondes. Garde l’attention de ton chien, mais évite de faire des mouvements brusques qui pourraient le distraire.',
        'S’il parvient à maintenir sa position, presse le clicker et reviens vers lui.',
        'Récompense avec une friandise et des caresses, en le félicitant pour son bon comportement.',
        'Pratique cet exercice en augmentant graduellement la distance entre toi et ton chien, ainsi que le temps qu’il doit rester en place.'
      ]
    },
    {
      id: '6',
      name: 'Laisse-le',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Placez une friandise ou un jouet préféré devant votre chien, mais hors de sa portée.',
        'Lorsqu\'il montre de l\'intérêt pour l\'objet, dites "Laisse-le" de manière claire et ferme.',
        'Gardez votre main fermée autour de la friandise et attendez qu\'il détourne son attention de l\'objet.',
        'Dès qu\'il détourne son attention, même si c\'est juste pour une seconde, appuyez sur le clicker.',
        'Offrez une récompense de votre autre main, en vous assurant qu\'elle est meilleure que la friandise ou le jouet initial.',
        'Répétez ce processus dans différents scénarios et avec différents objets pour solidifier le comportement.',
        'Au fur et à mesure qu\'il s\'améliore, augmentez la difficulté en lui permettant de s\'approcher davantage de l\'objet avant de donner l\'ordre "Laisse-le".'
      ]
    },
    {
      id: '7',
      name: 'Tourner',
      image: 'https://app.hundezonen.ch/docs/pomeranian-with-yellow-fur-lying-grass-it-s-back.jpg',
      steps: [
        'Placez-vous devant votre chien et tenez une friandise près de son nez pour capter son attention.',
        'Dites "Tourner" et déplacez lentement la friandise autour de sa tête, en l\'encourageant à suivre l\'odeur.',
        'S\'il suit l\'odeur et effectue un tour complet, appuyez immédiatement sur le clicker.',
        'Donnez-lui la friandise et offrez des caresses et des éloges verbaux comme renforcement positif.',
        'Répétez le processus plusieurs fois lors de différentes séances d\'entraînement, en augmentant la vitesse du tour au fur et à mesure qu\'il s\'améliore.',
        'Une fois qu\'il a maîtrisé la commande avec l\'aide de la friandise, pratiquez en donnant seulement la commande verbale pour généraliser la compétence.'
      ]
    },
    {
      id: '8',
      name: 'Parler et Taire',
      image: 'https://app.hundezonen.ch/docs/tired-after-good-walk-chocolate-labrador-retriever-dog-sits-yawn.jpg',
      steps: [
        'Trouvez un stimulus qui fait aboyer votre chien, comme une sonnette de porte ou un jouet.',
        'Lorsqu\'il commence à aboyer en réponse au stimulus, dites "Parle" et appuyez immédiatement sur le clicker.',
        'Donnez-lui une récompense et des éloges verbaux.',
        'Attendez qu\'il cesse d\'aboyer naturellement ou utilisez une commande comme "Chut" pour l\'arrêter.',
        'Une fois qu\'il s\'est arrêté, même si ce n\'est que pour un instant, appuyez sur le clicker et dites "Tais-toi".',
        'Offrez une friandise et des éloges verbaux comme récompense.',
        'Pratiquez cela lors de différentes sessions, en vous assurant qu\'il peut aboyer et s\'arrêter sur commande avant de l\'essayer dans des situations du monde réel.'
      ]
    },
    {
      id: '9',
      name: 'Sauter',
      image: 'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',
      steps: [
        'Commencez par une barrière basse que vous savez que votre chien peut facilement franchir.',
        'Amenez-le vers la barrière avec une laisse et placez une friandise ou un jouet de l\'autre côté pour le motiver.',
        'Donnez la commande "Sauter" tout en lui faisant un signal de la main ou du corps pour l\'encourager à sauter.',
        'Dès que ses pattes avant et arrière ont franchi la barrière, appuyez sur le clicker.',
        'Récompensez-le immédiatement avec la friandise ou le jouet et offrez beaucoup d\'éloges verbaux et de caresses.',
        'Pratiquez cela plusieurs fois, en augmentant progressivement la hauteur de la barrière et en éliminant le besoin d\'une récompense de l\'autre côté.'
      ]
    },
    {
      id: '10',
      name: 'Chercher',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Asseyez-vous sur le sol ou sur une chaise avec votre chien devant vous pour minimiser les distractions.',
        'Montrez à votre chien un jouet ou une friandise et lancez-le à une courte distance en disant "Cherche".',
        'S\'il va après l\'objet, appuyez sur le clicker pour marquer le comportement.',
        'Lorsqu\'il revient avec l\'objet, donnez la commande "Lâche" et appuyez sur le clicker lorsqu\'il le lâche.',
        'Récompensez-le avec une friandise différente ou un jeu interactif comme récompense.',
        'Répétez le processus, en augmentant progressivement la distance. Assurez-vous de pratiquer à la fois à l\'intérieur et à l\'extérieur pour généraliser le comportement.'
      ]
    },
    {
      id: '11',
      name: 'Récupérer et Apporter',
      image: 'https://app.hundezonen.ch/docs/cute-dog-playing-with-little-rubber-balls.jpg',
      steps: [
        'Apprenez d\'abord à votre chien à "Laisser" et "Prendre" un objet.',
        'Une fois qu\'il a compris, placez un objet sur le sol.',
        'Dites "Prends" et pointez l\'objet.',
        'Lorsqu\'il l\'a dans sa gueule, appuyez sur le clicker.',
        'Dites "Viens" et appuyez sur le clicker quand il vient vers vous.',
        'Dites "Laisse" et appuyez sur le clicker quand il laisse tomber l\'objet.',
        'S\'il fait correctement, appuyez sur le clicker et récompensez-le.',
        'Pratiquez dans de courtes sessions et augmentez la difficulté progressivement.'
      ]
    },
    {
      id: '12',
      name: 'Marcher à vos côtés',
      image: 'https://app.hundezonen.ch/docs/woman-walking-with-her-dog-walkway-park.jpg',
      steps: [
        'Commencez avec une laisse courte dans un environnement contrôlé.',
        'Laissez le chien renifler et explorer un peu.',
        'Commencez à marcher lentement en disant "Allons-y".',
        'Chaque fois qu\'il vous regarde ou marche à côté de vous, appuyez sur le clicker.',
        'Arrêtez-vous et offrez-lui une récompense.',
        'Continuez à pratiquer, en augmentant la durée et la difficulté de la promenade.',
        'S\'il s\'avance ou recule, arrêtez-vous jusqu\'à ce qu\'il revienne à la bonne position, puis appuyez sur le clicker.',
        'Pratiquez dans différents environnements et niveaux de distraction.'
      ]
    },
    {
      id: '13',
      name: 'Monter et Descendre les Escaliers',
      image: 'https://app.hundezonen.ch/docs/woman-walking-her-dog-city-while-it-rains.jpg',
      steps: [
        'Placez votre chien au bas d\'un escalier et tenez-vous à côté de lui.',
        'Dites "Monte" et commencez à monter les escaliers.',
        'S\'il suit et monte au moins une marche, appuyez sur le clicker.',
        'Récompensez-le avec une friandise et des caresses.',
        'Pour descendre, utilisez la commande "Descend" et faites le même processus mais dans le sens inverse.',
        'Pratiquez d\'abord avec quelques marches, puis augmentez le nombre.',
        'S\'il montre une résistance, ne le forcez pas. Pratiquez davantage sur les marches inférieures jusqu\'à ce qu\'il gagne en confiance.'
      ]
    },
    {
      id: '14',
      name: 'Commandes à Distance',
      image: 'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',
      steps: [
        'Commencez par pratiquer des commandes de base comme "Assis" et "Couché", mais ajoutez maintenant une distance entre vous et le chien.',
        'Reculez d\'un pas et donnez l\'ordre "Assis".',
        'S\'il le fait correctement, appuyez sur le clicker et revenez pour donner la récompense.',
        'Augmentez progressivement la distance entre vous et votre chien.',
        'Assurez-vous de pratiquer cela dans un environnement sûr et sans distractions.',
        'Une fois maîtrisé, vous pouvez pratiquer dans des endroits avec plus de distractions.'
      ]
    },
    {
      id: '15',
      name: 'Ignorer les Distractions',
      image: 'https://app.hundezonen.ch/docs/old-person-with-their-pet-dog.jpg',
      steps: [
        'Placez une friandise ou un jouet près mais hors de portée de votre chien.',
        'Demandez à votre chien de s\'asseoir et de rester immobile.',
        'Marchez vers l\'objet et revenez.',
        'S\'il reste assis, appuyez sur le clicker et offrez une récompense.',
        'Répétez, mais cette fois touchez l\'objet avant de revenir.',
        'Continuez à augmenter la difficulté, récupérez finalement l\'objet et ne le donnez que s\'il reste assis.',
        'Pratiquez avec différents objets et niveaux de distraction.'
      ]
    },
    {
      id: '16',
      name: 'Reconnaissance du Nom de l’Objet',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Commencez par présenter un seul jouet et donnez-lui un nom spécifique, comme "Balle".',
        'Dites "Où est la Balle ?" et encouragez votre chien à la prendre.',
        'Si cela est fait correctement, appuyez sur le clicker et offrez une récompense.',
        'Répétez le processus en ajoutant plus de jouets un par un, en utilisant des noms différents.',
        'Mélangez les jouets et demandez au chien de trouver un objet spécifique ("Où est l’Os ?", "Où est le Frisbee ?").',
        'Appuyez sur le clicker et offrez une récompense à chaque fois qu’ils trouvent l’objet correct.',
        'Pratiquez régulièrement pour renforcer leurs compétences de reconnaissance.'
      ]
    },
    {
      id: '17',
      name: 'Asseyez-vous en Silence',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Commencez dans un environnement sans distractions.',
        'Donnez le commandement "Assis" et attendez qu’ils s’asseyent.',
        'Une fois assis, donnez le commandement "Silence" tout en maintenant le contact visuel.',
        'S’ils restent assis et silencieux pendant quelques secondes, appuyez sur le clicker et offrez une récompense.',
        'Augmentez progressivement le temps qu’ils doivent rester silencieux avant de recevoir la récompense.',
        'Introduisez progressivement des distractions et pratiquez dans différents environnements.'
      ]
    },
    {
      id: '18',
      name: 'Prendre la Laisse',
      image: 'https://app.hundezonen.ch/docs/pet-owner-putting-dog-collar-his-pets-neck.jpg',
      steps: [
        'Laissez tomber la laisse près du chien tout en disant le commandement "Laisse".',
        'S’ils la touchent ou la prennent, appuyez sur le clicker et offrez une récompense.',
        'Pratiquez ce comportement plusieurs fois.',
        'Augmentez la difficulté en leur demandant de vous apporter la laisse.',
        'Si cela est fait correctement, appuyez sur le clicker et offrez une récompense.',
        'Finalement, pratiquez dans différentes pièces pour augmenter la complexité.'
      ]
    },
    {
      id: '19',
      name: 'Touche de Patte',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Présentez votre main ouverte devant votre chien et donnez le commandement "Touche".',
        'Attendez qu’ils touchent votre main avec leur patte.',
        'Appuyez sur le clicker et offrez immédiatement une récompense.',
        'Répétez l’exercice plusieurs fois jusqu’à ce qu’ils le fassent de manière cohérente.',
        'Augmentez la difficulté en leur demandant de toucher des objets spécifiques avec leur patte.',
        'Si cela est réussi, appuyez sur le clicker et offrez une récompense.'
      ]
    },
    {
      id: '20',
      name: 'Éviter la Nourriture au Sol',
      image: 'https://app.hundezonen.ch/docs/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food.jpg',
      steps: [
        'Placez une friandise sur le sol et donnez le commandement "Non".',
        'S’ils évitent de prendre la friandise, appuyez sur le clicker et offrez une récompense de votre main.',
        'Pratiquez plusieurs fois.',
        'Introduisez de nouveaux types de nourriture et d’objets pour généraliser le comportement.',
        'S’ils évitent différents types de nourriture et d’objets au sol, appuyez sur le clicker et offrez une récompense.',
        'Pratiquez dans différents endroits et avec différents types de nourriture.'
      ]
    },
    {
      id: '21',
      name: 'Entraînement à la Baguette Cible',
      image: 'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',
      steps: [
        'Présentez une baguette avec une extrémité pointue devant votre chien.',
        'Dites "Touche" tout en pointant la cible avec la baguette.',
        'Lorsque votre chien touche la cible avec son nez ou sa patte, appuyez sur le clicker.',
        'Récompensez avec une friandise et des éloges verbaux.',
        'Augmentez la difficulté en déplaçant la cible à différentes hauteurs ou directions.'
      ]
    },
    {
      id: '22',
      name: 'Marche en Zigzag',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Commencez à marcher avec votre chien à vos côtés avec une laisse courte.',
        'Commencez à faire des mouvements en zigzag en changeant de direction.',
        'Lorsque votre chien vous suit correctement dans les virages, appuyez sur le clicker.',
        'Récompensez avec une friandise et des éloges verbaux.',
        'Entraînez dans différents endroits avec différents niveaux de distraction.'
      ]
    },
    {
      id: '23',
      name: 'Salutation Polie',
      image: 'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',
      steps: [
        'Apprenez à votre chien à saluer les gens sans sauter.',
        'Quand votre chien s\'apprête à sauter, dites "Non" et gardez vos mains fermées.',
        'Quand votre chien garde ses quatre pattes sur le sol, appuyez sur le clicker.',
        'Récompensez avec une friandise et permettez à la personne de caresser le chien.',
        'Entraînez avec des amis et de la famille pour généraliser le comportement.'
      ]
    },
    {
      id: '24',
      name: 'Recherche et Trouvez',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Cachez une friandise ou un jouet à un endroit visible pour votre chien au début.',
        'Dites "Recherche" et montrez où se trouve l\'objet.',
        'Lorsque votre chien trouve l\'objet, appuyez sur le clicker.',
        'Célébrez avec une récompense et des éloges verbaux.',
        'Augmentez progressivement la difficulté en cachant l\'objet à des endroits plus difficiles.'
      ]
    },
    {
      id: '25',
      name: 'Éteindre la Lumière',
      image: 'https://app.hundezonen.ch/docs/cute-purebred-puppy-sitting-looking-camera-generated-by-ai_188544-43521.jpg',
      steps: [
        'Entraînez votre chien à éteindre un interrupteur accessible.',
        'Dites "Lumière" et montrez l\'interrupteur.',
        'Lorsque votre chien appuie sur l\'interrupteur avec son nez ou sa patte, appuyez sur le clicker.',
        'Récompensez avec une friandise et des éloges verbaux.',
        'Commencez dans une pièce avec peu de lumière et augmentez progressivement la difficulté.'
      ]
    },
    {
      id: '26',
      name: 'Parcours d\'Obstacles',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Installez des obstacles comme des cônes ou de petites haies dans votre zone d\'entraînement.',
        'Utilisez des commandes comme "Saute" ou "Passe" tout en guidant votre chien à travers les obstacles.',
        'Appuyez sur le clicker lorsque votre chien réussit à compléter le parcours d\'obstacles.',
        'Récompensez avec une friandise et des éloges verbaux.',
        'Augmentez progressivement la hauteur et la complexité des obstacles.'
      ]
    }    

  ],

  it: [
    {
      id: '1',
      name: 'Dare la zampa',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Chiedi al tuo cane di sedersi su una superficie piatta e comoda.',
        'Avvicinati e tendi la mano verso la sua zampa, dicendo "Zampa" in modo chiaro e calmo.',
        'Se reagisce spostando la sua zampa, anche solo di un po\', premi subito il clicker.',
        'Ricompensa l\'azione con un premio e con rinforzi positivi come carezze o lodi verbali.',
        'Pratica questo in diverse sessioni, aumentando progressivamente il tempo che deve tenere la zampa nella tua mano prima di ricevere la ricompensa.'
      ]
    },
    {
      id: '2',
      name: 'Sedere',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Posiziona un premio vicino al naso del tuo cane.',
        'Muovi la mano verso l\'alto, consentendo alla sua testa di seguire il premio e al suo sedere di abbassarsi.',
        'Appena il suo sedere tocca il suolo, premi il clicker.',
        'Dagli il premio e fai delle carezze.',
        'Ripeti questi passaggi diverse volte.'
      ]
    },
    {
      id: '3',
      name: 'Distendersi',
      image: 'https://app.hundezonen.ch/docs/portrait-little-cute-dog-calmly-lying-posing-isolated-white-studio-background.jpg',
      steps: [
        'Inizia con il tuo cane in posizione seduta e assicurati che sia concentrato su di te.',
        'Tieni un premio nella tua mano e mostralo al tuo cane per catturare il suo interesse.',
        'Abbassa la tua mano gradualmente verso il suolo, facendo in modo che la sua testa segua il movimento.',
        'Appena si distende a terra, premi subito il clicker.',
        'Dagli il premio e fornisci carezze e lodi verbali come rinforzo positivo.',
        'Pratica diverse volte e in diversi ambienti fino a quando non sarà in grado di distendersi con un semplice segnale o comando verbale.'
      ]
    },
    {
      id: '4',
      name: 'Venire',
      image: 'https://app.hundezonen.ch/docs/closeup-bsorder-collie-deog-running-field.jpg',
      steps: [
        'Assicurati di trovarti in un ambiente sicuro e controllato, come un giardino chiuso o un\'area di addestramento.',
        'Metti al tuo cane un guinzaglio lungo per garantire il suo controllo durante l\'addestramento.',
        'Allontanati da lui di alcuni metri e chiamalo dicendo "Vieni" mentre fai una leggera trazione sul guinzaglio.',
        'Nel momento in cui inizia a muoversi verso di te, premi il clicker per segnalare il comportamento.',
        'Quando arriva da te, premi nuovamente il clicker e ricompensalo con premi e lodi verbali.',
        'Pratica questo comando in sessioni brevi ma frequenti, aumentando progressivamente la distanza tra di voi.'
      ]
    },
    {
      id: '5',
      name: 'Restare',
      image: 'https://app.hundezonen.ch/docs/labrador-retrieve.jpg',
      steps: [
        'Chiedi al tuo cane di sedersi o di distendersi in un luogo tranquillo.',
        'Mantieni il contatto visivo e di "Resta" mentre estendi la tua mano in un gesto di "stop" di fronte al suo viso.',
        'Fai un passo indietro e aspetta qualche secondo. Mantieni l\'attenzione del tuo cane, ma evita di fare movimenti bruschi che potrebbero distrarlo.',
        'Se riesce a mantenere la posizione, premi il clicker e torna da lui.',
        'Ricompensa con un premio e delle carezze, lodando il suo buon comportamento.',
        'Pratica questo esercizio aumentando gradualmente la distanza tra te e il tuo cane, così come il tempo che deve rimanere fermo.'
      ]
    },
    {
      id: '6',
      name: 'Lascialo',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Metti un premio o un giocattolo preferito davanti al tuo cane, ma fuori dalla sua portata.',
        'Quando mostra interesse per l\'oggetto, dì "Lascialo" in modo chiaro e deciso.',
        'Tieni la mano chiusa intorno al premio e aspetta che distolga l\'attenzione dall\'oggetto.',
        'Non appena distoglie l\'attenzione, anche solo per un secondo, premi il clicker.',
        'Offri un premio con l\'altra mano, assicurandoti che sia migliore del premio o del giocattolo iniziale.',
        'Ripeti questo processo in diversi scenari e con diversi oggetti per consolidare il comportamento.',
        'Man mano che migliora, aumenta la difficoltà permettendogli di avvicinarsi di più all\'oggetto prima di dare il comando "Lascialo".'
      ]
    },
    {
      id: '7',
      name: 'Girare',
      image: 'https://app.hundezonen.ch/docs/pomeranian-with-yellow-fur-lying-grass-it-s-back.jpg',
      steps: [
        'Mettiti davanti al tuo cane e tieni un premio vicino al suo naso per catturare la sua attenzione.',
        'Dì "Gira" e muovi lentamente il premio intorno alla sua testa, incoraggiandolo a seguire l\'odore.',
        'Se segue l\'odore e compie un giro completo, premi immediatamente il clicker.',
        'Dagli il premio e offri rinforzi positivi come carezze o elogi verbali.',
        'Ripeti il processo più volte durante diverse sessioni di allenamento, aumentando la velocità del giro man mano che migliora.',
        'Una volta che ha padroneggiato il comando con l\'aiuto del premio, pratica dando solo il comando verbale per generalizzare l\'abilità.'
      ]
    },
    {
      id: '8',
      name: 'Parlare e Tacere',
      image: 'https://app.hundezonen.ch/docs/tired-after-good-walk-chocolate-labrador-retriever-dog-sits-yawn.jpg',
      steps: [
        'Trova uno stimolo che faccia abbaiare il tuo cane, come un campanello d\'ingresso o un giocattolo.',
        'Quando inizia ad abbaiare in risposta allo stimolo, dì "Parla" e premi immediatamente il clicker.',
        'Dagli un premio e elogi verbali.',
        'Aspetta che smetta di abbaiare naturalmente o usa un comando come "Shh" per fermarlo.',
        'Una volta che si è fermato, anche solo per un momento, premi il clicker e dì "Taci".',
        'Offri un premio e elogi verbali come ricompensa.',
        'Pratica questo durante diverse sessioni, assicurandoti che possa abbaiare e fermarsi a comando prima di provarlo in situazioni reali.'
      ]
    },
    {
      id: '9',
      name: 'Saltare',
      image: 'https://app.hundezonen.ch/docs/running-cute-sweet-puppy-lagotto-romagnolo-cute-dog-pet-posing-white.jpg',
      steps: [
        'Inizia con una barriera bassa che sai che il tuo cane può superare facilmente.',
        'Portalo verso la barriera con un guinzaglio e metti un premio o un giocattolo dall\'altra parte per motivarlo.',
        'Dai il comando "Salta" mentre fai un segnale con la mano o il corpo per incoraggiarlo a saltare.',
        'Non appena le zampe anteriori e posteriori hanno superato la barriera, premi il clicker.',
        'Ricompensalo immediatamente con il premio o il giocattolo e offri un sacco di elogi verbali e carezze.',
        'Pratica questo più volte, aumentando gradualmente l\'altezza della barriera e eliminando la necessità di una ricompensa dall\'altra parte.'
      ]
    },
    {
      id: '10',
      name: 'Cercare',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Siediti per terra o su una sedia con il tuo cane davanti a te per minimizzare le distrazioni.',
        'Mostra al tuo cane un giocattolo o un premio e lancialo a breve distanza mentre dici "Cerca".',
        'Se va dietro l\'oggetto, premi il clicker per segnare il comportamento.',
        'Quando torna con l\'oggetto, dai il comando "Lascia" e premi il clicker quando lo lascia.',
        'Ricompensalo con un premio diverso o un gioco interattivo come ricompensa.',
        'Ripeti il processo, aumentando gradualmente la distanza. Assicurati di praticare sia all\'interno che all\'esterno per generalizzare il comportamento.'
      ]
    },
    {
      id: '11',
      name: 'Raccogliere e Consegnare',
      image: 'https://app.hundezonen.ch/docs/cute-dog-playing-with-little-rubber-balls.jpg',
      steps: [
        'Prima insegna al tuo cane a "Lasciare" e "Prendere" un oggetto.',
        'Una volta capito, posiziona un oggetto a terra.',
        'Di "Prendi" e indica l\'oggetto.',
        'Quando lo ha in bocca, premi il clicker.',
        'Di "Vieni" e premi il clicker quando viene verso di te.',
        'Di "Lascia" e premi il clicker quando rilascia l\'oggetto.',
        'Se lo fa correttamente, premi il clicker e dagli una ricompensa.',
        'Pratica in sessioni brevi e aumenta la difficoltà gradualmente.'
      ]
    },
    {
      id: '12',
      name: 'Camminare al tuo fianco',
      image: 'https://app.hundezonen.ch/docs/woman-walking-with-her-dog-walkway-park.jpg',
      steps: [
        'Inizia con un guinzaglio corto in un ambiente controllato.',
        'Lascia che il cane annusi ed esplori un po\'.',
        'Inizia a camminare lentamente dicendo "Andiamo".',
        'Ogni volta che ti guarda o cammina al tuo fianco, premi il clicker.',
        'Fermati e offrigli una ricompensa.',
        'Continua a praticare, aumentando la durata e la difficoltà della passeggiata.',
        'Se si sposta avanti o indietro, fermati fino a quando non ritorna nella posizione corretta, poi premi il clicker.',
        'Pratica in diversi ambienti e livelli di distrazione.'
      ]
    },
    {
      id: '13',
      name: 'Salire e Scendere le Scale',
      image: 'https://app.hundezonen.ch/docs/woman-walking-her-dog-city-while-it-rains.jpg',
      steps: [
        'Posiziona il tuo cane alla base delle scale e stai al suo fianco.',
        'Di "Su" e inizia a salire le scale.',
        'Se ti segue e sale almeno un gradino, premi il clicker.',
        'Ricompensalo con un premio e carezze.',
        'Per scendere, usa il comando "Giù" e fai lo stesso processo ma al contrario.',
        'Pratica prima con pochi gradini, poi aumenta la quantità.',
        'Se mostra resistenza, non forzare. Pratica di più sui gradini inferiori fino a quando guadagna fiducia.'
      ]
    },
    {
      id: '14',
      name: 'Comandi a Distanza',
      image: 'https://app.hundezonen.ch/docs/cute-dog-kids-park.jpg',
      steps: [
        'Inizia praticando comandi di base come "Siediti" e "Sdraiati" ma ora aggiungendo una distanza tra te e il cane.',
        'Fai un passo indietro e dai il comando "Siediti".',
        'Se lo fa correttamente, premi il clicker e ritorna per dargli la ricompensa.',
        'Aumenta gradualmente la distanza tra te e il tuo cane.',
        'Assicurati di praticare questo in un ambiente sicuro e senza distrazioni.',
        'Una volta padroneggiato, puoi praticare in luoghi con più distrazioni.'
      ]
    },
    {
      id: '15',
      name: 'Ignorare le Distrazioni',
      image: 'https://app.hundezonen.ch/docs/old-person-with-their-pet-dog.jpg',
      steps: [
        'Posiziona un premio o un giocattolo vicino ma fuori dalla portata del tuo cane.',
        'Chiedi al tuo cane di sedersi e rimanere fermo.',
        'Cammina verso l\'oggetto e torna indietro.',
        'Se rimane seduto, premi il clicker e offri una ricompensa.',
        'Ripeti, ma questa volta tocca l\'oggetto prima di tornare.',
        'Continua ad aumentare la difficoltà, prendi eventualmente l\'oggetto e dandoglielo solo se rimane seduto.',
        'Pratica con oggetti diversi e livelli di distrazione.'
      ]
    },
    {
      id: '16',
      name: 'Riconoscimento del Nome dell’Oggetto',
      image: 'https://app.hundezonen.ch/docs/ddday.jpg',
      steps: [
        'Inizia presentando un singolo giocattolo e dandogli un nome specifico, come "Palla".',
        'Dì "Dove è la Palla?" e incoraggia il tuo cane a prenderla.',
        'Se fatto correttamente, premi il clicker e offri una ricompensa.',
        'Ripeti il processo aggiungendo più giocattoli uno alla volta, usando nomi diversi.',
        'Mescola i giocattoli e chiedi al cane di trovare un oggetto specifico ("Dove è l’Osso?", "Dove è il Frisbee?").',
        'Premi il clicker e offri una ricompensa ogni volta che trova l’oggetto corretto.',
        'Pratica regolarmente per rafforzare le loro capacità di riconoscimento.'
      ]
    },
    {
      id: '17',
      name: 'Sedere in Silenzio',
      image: 'https://app.hundezonen.ch/docs/woman-petting-adorable-dog.jpg',
      steps: [
        'Inizia in un ambiente senza distrazioni.',
        'Dai il comando "Siedi" e aspetta che si sieda.',
        'Una volta seduto, dai il comando "Zitto" mantenendo il contatto visivo.',
        'Se rimane seduto e silenzioso per alcuni secondi, premi il clicker e offri una ricompensa.',
        'Aumenta gradualmente il tempo che deve rimanere in silenzio prima di ricevere la ricompensa.',
        'Introduci gradualmente distrazioni e pratica in diverse impostazioni.'
      ]
    },
    {
      id: '18',
      name: 'Prendere il Guinzaglio',
      image: 'https://app.hundezonen.ch/docs/pet-owner-putting-dog-collar-his-pets-neck.jpg',
      steps: [
        'Lascia cadere il guinzaglio vicino al cane mentre dici il comando "Guinzaglio".',
        'Se lo tocca o lo raccoglie, premi il clicker e offri una ricompensa.',
        'Pratica questo comportamento più volte.',
        'Aumenta la difficoltà chiedendo di portarti il guinzaglio.',
        'Se fatto con successo, premi il clicker e offri una ricompensa.',
        'Infine, pratica in stanze diverse per aumentare la complessità.'
      ]
    },
    {
      id: '19',
      name: 'Tocco di Zampa',
      image: 'https://app.hundezonen.ch/docs/paw-dossg-human-hand.jpg',
      steps: [
        'Presenta la tua mano aperta di fronte al tuo cane e dai il comando "Tocca".',
        'Aspetta che tocchi la tua mano con la zampa.',
        'Premi il clicker e offri immediatamente una ricompensa.',
        'Ripeti l’esercizio più volte fino a quando non lo fa in modo coerente.',
        'Aumenta la difficoltà chiedendo di toccare oggetti specifici con la zampa.',
        'Se fatto con successo, premi il clicker e offri una ricompensa.'
      ]
    },
    {
      id: '20',
      name: 'Evitare Cibo a Terra',
      image: 'https://app.hundezonen.ch/docs/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food.jpg',
      steps: [
        'Metti un boccone a terra e dai il comando "No".',
        'Se evita di prendere il boccone, premi il clicker e offri una ricompensa dalla tua mano.',
        'Pratica più volte.',
        'Introduci nuovi tipi di cibo e oggetti per generalizzare il comportamento.',
        'Se evita diversi cibi e oggetti a terra, premi il clicker e offri una ricompensa.',
        'Pratica in diverse posizioni e con diversi tipi di cibo.'
      ]
    },
    {
      id: '21',
      name: 'Addestramento con Asta Bersaglio',
      image: 'https://app.hundezonen.ch/docs/cute-labrador-playing-fetch-outdoor.jpg',
      steps: [
        'Presenta al tuo cane un\'asta con una punta appuntita davanti a lui.',
        'Di "Tocca" mentre indichi il bersaglio con l\'asta.',
        'Quando il tuo cane tocca il bersaglio con il naso o la zampa, premi il clicker.',
        'Ricompensa con una ricompensa alimentare e lodi verbali.',
        'Aumenta la difficoltà spostando il bersaglio a diverse altezze o direzioni.'
      ]
    },
    {
      id: '22',
      name: 'Camminata a Zigzag',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Inizia a camminare con il tuo cane al tuo fianco con un guinzaglio corto.',
        'Inizia a fare movimenti a zigzag, cambiando direzione.',
        'Quando il tuo cane ti segue correttamente nelle svolte, premi il clicker.',
        'Ricompensa con una ricompensa alimentare e lodi verbali.',
        'Esercitati in luoghi diversi con diversi livelli di distrazione.'
      ]
    },
    {
      id: '23',
      name: 'Saluto Educato',
      image: 'https://app.hundezonen.ch/docs/adorable-brown-white-basenji-dog-smiling-giving-high-five-isolated-white.jpg',
      steps: [
        'Insegna al tuo cane a salutare le persone senza saltare.',
        'Quando il tuo cane sta per saltare, di "No" e mantieni le mani chiuse.',
        'Quando il tuo cane mantiene tutte e quattro le zampe a terra, premi il clicker.',
        'Ricompensa con una ricompensa alimentare e permetti alla persona di accarezzare il cane.',
        'Esercitati con amici e familiari per generalizzare il comportamento.'
      ]
    },
    {
      id: '24',
      name: 'Cerca e Trova',
      image: 'https://app.hundezonen.ch/docs/white-black-border-collie-forest-landscape.jpg',
      steps: [
        'Nascondi una ghiottoneria o un giocattolo in un luogo visibile per il tuo cane all\'inizio.',
        'Di "Cerca" e indica dove si trova l\'oggetto.',
        'Quando il tuo cane trova l\'oggetto, premi il clicker.',
        'Festeggia con una ricompensa alimentare e lodi verbali.',
        'Aumenta gradualmente la difficoltà nascondendo l\'oggetto in luoghi più impegnativi.'
      ]
    },
    {
      id: '25',
      name: 'Spegni la Luce',
      image: 'https://app.hundezonen.ch/docs/cute-purebred-puppy-sitting-looking-camera-generated-by-ai_188544-43521.jpg',
      steps: [
        'Addestra il tuo cane a spegnere un interruttore luminoso accessibile.',
        'Di "Luce" e indica l\'interruttore.',
        'Quando il tuo cane preme l\'interruttore con il naso o la zampa, premi il clicker.',
        'Ricompensa con una ricompensa alimentare e lodi verbali.',
        'Inizia in una stanza con poca luce e aumenta gradualmente la difficoltà.'
      ]
    },
    {
      id: '26',
      name: 'Percorso ad Ostacoli',
      image: 'https://app.hundezonen.ch/docs/sportive-dog-performing-during-show-competition.jpg',
      steps: [
        'Prepara ostacoli come coni o piccoli ostacoli nella tua area di addestramento.',
        'Usa comandi come "Salta" o "Passa" mentre guidi il tuo cane attraverso gli ostacoli.',
        'Premi il clicker quando il tuo cane completa con successo il percorso ad ostacoli.',
        'Ricompensa con una ricompensa alimentare e lodi verbali.',
        'Aumenta gradualmente l\'altezza e la complessità degli ostacoli.'
      ]
    }       
  ]
};

const greetingsTranslations = {
  en: 'Hello! What trick would you like to teach your dog today?',
  es: '¡Hola! ¿Qué truco te gustaría enseñarle a tu perro hoy?',
  de: 'Hallo! Welchen Trick möchtest du deinem Hund heute beibringen?',
  fr: 'Bonjour! Quel tour aimeriez-vous apprendre à votre chien aujourd\'hui?',
  it: 'Ciao! Quale trucco vorresti insegnare al tuo cane oggi?',
};

const nextButtonTranslations = {
  en: 'Next ➤',
  es: 'Siguiente ➤',
  de: 'Nächster ➤',
  fr: 'Suivant ➤',
  it: 'Prossimo ➤',
};

const soundButtonTranslations = {
  sound1: {
    en: 'Clicker',
    es: 'Clicker',
    de: 'Clicker',
    fr: 'Clicker',
    it: 'Clicker',
  },
  sound2: {
    en: 'Whistle',
    es: 'Silbato',
    de: 'Pfiff',
    fr: 'Sifflet',
    it: 'Fischietto',
  },
};



const completionMessageTranslations = {
  en: "Well done! You have completed this exercise 👍",
  es: "¡Bien hecho! Has completado este ejercicio 👍",
  de: "Gut gemacht! Sie haben diese Übung abgeschlossen 👍",
  fr: "Bien joué ! Vous avez terminé cet exercice 👍",
  it: "Ben fatto! Hai completato quest'esercizio 👍",
  tr: "Aferin! Bu egzersizi tamamladınız 👍"
};


const currentLanguage = selectedLanguage; // OBTENGA EL IDIOMA SELECCIONADO AQUÍ

const greetingMessage = greetingsTranslations[currentLanguage] || greetingsTranslations['en'];
const nextButtonLabel = nextButtonTranslations[currentLanguage] || nextButtonTranslations['en'];
const sound1ButtonLabel = soundButtonTranslations.sound1[currentLanguage] || soundButtonTranslations.sound1['en'];
const sound2ButtonLabel = soundButtonTranslations.sound2[currentLanguage] || soundButtonTranslations.sound2['en'];


// Estado para almacenar los ejercicios en el idioma actual
const [exercises, setExercises] = useState(exercisesTranslations[selectedLanguage] || exercisesTranslations['en']);
  
useEffect(() => {
  setExercises(exercisesTranslations[selectedLanguage] || exercisesTranslations['en']);
}, [selectedLanguage]);

// Manejar el avance de los pasos del ejercicio
useEffect(() => {
  if (selectedExercise && currentStep < selectedExercise.steps.length) {
    advanceStep(selectedExercise);
  }
}, [currentStep, selectedExercise]);

useEffect(() => {
  if (lastNextButtonClick && completionMessage && scrollEndRef.current) {
    scrollEndRef.current.measure((fx, fy, width, height, px, py) => {
      scrollViewRef.current.scrollTo({ y: py + height, animated: true });
    });
  }
}, [lastNextButtonClick, completionMessage]);


const startExercise = (exercise) => {
  setSelectedExercise(exercise);
  setCurrentImage(exercise.image);
  setCurrentStep(0);
  setShowGreeting(false);
  setChatLog([{ message: `Vamos a enseñar a tu perro a ${exercise.name}`, role: 'bot' }]);
  setLastNextButtonClick(false); // Restablecer lastNextButtonClick
};


const clickerKeywords = {
  en: 'clicker',
  es: 'clicker',
  de: 'Clicker',
  fr: 'clicker',
  it: 'clicker'
};


const advanceStep = (exercise) => {

  // El resto del código sigue igual
  if (!exercise) return;
  if (currentStep < exercise.steps.length) {
    const stepMessage = exercise.steps[currentStep];
    setChatLog([{ message: stepMessage, role: 'bot' }]);

    if (Object.values(clickerKeywords).some(keyword => stepMessage.includes(keyword))) {
      setShowClicker(true);
      setShowNextButton(false);
    } else {
      setShowClicker(false);
      setShowNextButton(true);
    }
  } else {
    setChatLog([{ message: completionMessage, role: 'bot' }]);
    setSelectedExercise(null);
    setCurrentStep(0);
    setShowGreeting(true);
    setShowNextButton(false);
  }
};

const handleNextClick = () => {
  const completionMessage = completionMessageTranslations[currentLanguage] || completionMessageTranslations['en'];
  
  // El resto del código sigue igual
  if (!selectedExercise) return;
  
  const nextStep = currentStep + 1;
  setCurrentStep(nextStep);
  
  if (nextStep < selectedExercise.steps.length) {
    advanceStep(selectedExercise);
  } else {
    setLastNextButtonClick(true); // Se presionó el botón "Next" por última vez
    setChatLog([{ message: completionMessage, role: 'bot' }]);
    setSelectedExercise(null);
    setCurrentStep(0);
    setShowGreeting(true);
    setShowNextButton(false);
  }
};

  const handleButtonClick = () => {
    if (!selectedExercise) return;

    setShowClicker(false);
    setCurrentStep(currentStep + 1);
    advanceStep(selectedExercise);
  };

   // Función para manejar el sonido del clicker
   const handleClicker = async () => {
    try {
      if (sound) {
        await sound.unloadAsync();
      }
      const { sound: newSound } = await Audio.Sound.createAsync(
        soundMap[selectedSound]
      );
      setSound(newSound);
      await newSound.playAsync();
    } catch (error) {
      console.error('Error al reproducir el sonido:', error);
    }
  };
  
  // Función handlePress
  const handlePress = async () => {
    setIsPressed(true);
    
    // Usamos selectedSound para saber qué imagen presionada usar
    const { image, pressedImage } = soundImageMap[selectedSound];
    setImageSource(pressedImage);
  
    // Llama a la función que reproduce el sonido
    await handleClicker();
    
    // Anima la imagen
    Animated.timing(scaleAnim, {
      toValue: 1.2,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      setTimeout(() => {
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();
  
        setImageSource(image); // Usa la imagen original del selectedSound
        setIsPressed(false);
      }, 500);
    });
  };

  
  

  const completionMessage = completionMessageTranslations[currentLanguage] || completionMessageTranslations['en'];

  useEffect(() => {
    const fadeIn = () => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    };
  
    const fadeOut = () => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    };
  
    // Iniciar el fade-in al montar el componente
    fadeIn();
  
    const timer = setInterval(() => {
      // Iniciar el fade-out después de 4800 ms
      setTimeout(fadeOut, 4800);
  
      // Cambiar la imagen y reiniciar el fade-in después de 5000 ms
      setTimeout(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        fadeIn();
      }, 5000);
    }, 5000);
  
    return () => clearInterval(timer);
  }, []);
  
  

  return (
    
    
<SafeAreaView style={[styles.container, isNightMode && styles.nightModeContainer]}>
  <ScrollView ref={scrollViewRef}>
    {showGreeting && (
      <>
        {/* Muestra la imagen animada */}
        <View style={styles.animateImagen}>
          <Animated.Image
            source={{ uri: imageUrls[imageIndex] }}
            style={{
              opacity: fadeAnim,
              width: '100%',
              height: 200,
            }}
          />
        </View>
        
        {/* Muestra el título debajo de la imagen */}
        <Text style={[styles.greeting, isNightMode && styles.nightModeText]}>
          {greetingMessage}
        </Text>

        {/* Muestra los chips de ejercicio debajo del título */}
        <View style={styles.chipContainer}>
          {exercises.map((item) => (
            <Chip
              key={item.id}
              textStyle={isNightMode ? { color: '#fff' } : { color: '#000' }}
              style={[styles.chip, isNightMode && styles.nightModeChip]}
              onPress={() => startExercise(item)}
            >
              {item.name}
            </Chip>
          ))}
        </View>
      </>
    )}
    <View style={[styles.parentContainer, isNightMode && styles.nightModeParentContainer]}>
      
      {/* Solo muestra la imagen si showClicker es falso */}
      {!showClicker && currentImage && chatLog[chatLog.length - 1]?.message !== completionMessage && (
        <Image source={{ uri: currentImage }} style={styles.imageStyle} />
      )}

      {chatLog.map((entry, index) => (
        <View 
          key={index} 
          style={[
            styles.instructionContainer, 
            isNightMode && styles.nightModeInstructionContainer,
            entry.message === completionMessage && (isNightMode ? styles.nightModeCompletionMessageContainer : styles.completionMessageContainer)
          ]}
        >
          <Text 
            style={[
              styles.instructionText, 
              isNightMode && styles.nightModeText,
              entry.message === completionMessage && (isNightMode ? styles.nightModeCompletionMessageText : styles.completionMessageText)
            ]}
          >
            {entry.message}
          </Text>
        </View>
      ))}
    </View>

    {showNextButton && (
  <View style={[styles.buttonContainer, isNightMode && styles.nightModeButtonContainer]}>
    <TouchableOpacity 
      style={[styles.customButton, isNightMode && styles.nightModeButton]} 
      onPress={handleNextClick}
    >
      <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>{nextButtonLabel}</Text>
    </TouchableOpacity>
  </View>
)}

{showClicker && (
      <View style={[styles.buttonContainer, isNightMode && styles.nightModeButtonContainer]}>
        <TouchableOpacity activeOpacity={1} onPress={handlePress}>
          <Animated.Image
            source={{ uri: imageSource }}
            style={[
              styles.generalImageStyle,
              { transform: [{ scale: scaleAnim }] }
            ]}
          />

    </TouchableOpacity>
    <TouchableOpacity 
  style={selectedSound === 'sound1' ? [styles.selectedButton, isNightMode && styles.nightModeSelectedButton] : [styles.unselectedButton, isNightMode && styles.nightModeUnselectedButton]} 
  onPress={() => {
    setSelectedSound('sound1');  // Establece el sonido seleccionado como 'sound1'
    const { image } = soundImageMap['sound1'];  // Obtiene la imagen asociada con 'sound1'
    setImageSource(image);  // Establece la imagen
  }}
>
  <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>{sound1ButtonLabel}</Text>
</TouchableOpacity>

<TouchableOpacity 
  style={selectedSound === 'sound2' ? [styles.selectedButton, isNightMode && styles.nightModeSelectedButton] : [styles.unselectedButton, isNightMode && styles.nightModeUnselectedButton]} 
  onPress={() => {
    setSelectedSound('sound2');  // Establece el sonido seleccionado como 'sound2'
    const { image } = soundImageMap['sound2'];  // Obtiene la imagen asociada con 'sound2'
    setImageSource(image);  // Establece la imagen
  }}
>
  <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>{sound2ButtonLabel}</Text>
</TouchableOpacity>

<TouchableOpacity 
  style={[styles.customButton, isNightMode && styles.nightModeButton]} 
  onPress={handleNextClick}
>
  <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>{nextButtonLabel}</Text>
</TouchableOpacity>

  </View>
)}
<View ref={scrollEndRef} />
</ScrollView>
</SafeAreaView>
  
  );
};


export default ClickerTraining;