import React, { useState, useEffect, useRef  } from 'react';
import { ScrollView, View, Text, StyleSheet, Switch, TouchableOpacity, TextInput, Modal, Button } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Vibration } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ImageGeneratorModal from './ImageGeneratorModal'; // Importa el componente
import { Linking, Image } from 'react-native';
import * as Localization from 'expo-localization';
import { useNavigation } from '@react-navigation/native';
import { Animated, TouchableHighlight } from 'react-native';
import ClickerTraining from './ClickerTraining';
import Icon from 'react-native-vector-icons/Ionicons'; // Importa el icono que desees usar
import BreedModal from './DogBreedModal';
import TypingText from './components/TypingText'; // Ajusta la ruta según sea necesario


const SettingsScreen = ({
  isNightMode,
  onNightModeToggle,
  vibrationEnabled,
  onVibrationToggle,
  onCreateNewChat,
  showLanguageModal,
  onResponseModeChange,  // Añade esta línea
  currentLanguage

  
}) => {
  const navigation = useNavigation();
  const [temperature, setTemperature] = useState(0.7);
  const [responseMode, setResponseMode] = useState('general');
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [isAtSettings, setIsAtSettings] = useState(false);
  const [isBreedModalVisible, setBreedModalVisible] = useState(false);
  const [showBreedModal, setShowBreedModal] = useState(false);

  const settingsDescriptions = {
    en: {
        responseMode: "Chat Response Mode",
        general: "General",
        veterinarian: "Veterinarian",
        nutrición: "Nutrition",
        training: "Training",
        botTemperature: "Bot Temperature",
        newChat: "New Chat",
        changeLanguage: "Change Language",
        nightMode: "Night Mode",
        vibration: "Vibration",
        privacyPolicy: "Privacy Policy",
        gdpr: "GDPR",
        support: "Support"
    },
    es: {
        responseMode: "Elije el modo de respuesta de el Chat:",
        general: "General",
        veterinarian: "Veterinario",
        nutrición: "Nutrición",
        training: "Entrenamiento",
        botTemperature: "Temperatura del Bot",
        newChat: "Nuevo Chat",
        changeLanguage: "Cambiar Idioma",
        nightMode: "Modo Oscuro",
        vibration: "Vibración",
        privacyPolicy: "Política de Privacidad",
        gdpr: "RGPD",
        support: "Soporte"
    },
    fr: {
        responseMode: "Mode de Réponse du Chat",
        general: "Général",
        veterinarian: "Vétérinaire",
        nutrición: "Nutrition",
        training: "Formation",
        botTemperature: "Température du Bot",
        newChat: "Nouveau Chat",
        changeLanguage: "Changer de Langue",
        nightMode: "Mode Nuit",
        vibration: "Vibration",
        privacyPolicy: "Politique de Confidentialité",
        gdpr: "RGPD",
        support: "Soutien"
    },
    de: {
        responseMode: "Chat-Antwortmodus",
        general: "Allgemein",
        veterinarian: "Tierarzt",
        nutrición: "Ernährung",
        training: "Training",
        botTemperature: "Bot Temperatur",
        newChat: "Neuer Chat",
        changeLanguage: "Sprache ändern",
        nightMode: "Nachtmodus",
        vibration: "Vibration",
        privacyPolicy: "Datenschutzrichtlinie",
        gdpr: "AGB",
        support: "Kontakt"
    },
    it: {
        responseMode: "Modalità di Risposta della Chat",
        general: "Generale",
        veterinarian: "Veterinario",
        nutrición: "Nutrizione",
        training: "Allenamento",
        botTemperature: "Temperatura del Bot",
        newChat: "Nuova Chat",
        changeLanguage: "Cambia Lingua",
        nightMode: "Modalità Notte",
        vibration: "Vibrazione",
        privacyPolicy: "Politica sulla Privacy",
        gdpr: "GDPR",
        support: "Supporto"
    },
    tr: {
        responseMode: "Sohbet Yanıt Modu",
        general: "Genel",
        veterinarian: "Veteriner",
        nutrición: "Beslenme",
        training: "Eğitim",
        botTemperature: "Bot Sıcaklığı",
        newChat: "Yeni Sohbet",
        changeLanguage: "Dili Değiştir",
        nightMode: "Gece Modu",
        vibration: "Titreşim",
        privacyPolicy: "Gizlilik Politikası",
        gdpr: "KVKK",
        support: "Destek"
    }
};



const breedDescriptions = {
  en: {
      title: "Dog Breed Information",
      description: "Discover detailed information about different dog breeds."
  },
  es: {
      title: "Información de Raza de Perro",
      description: "Descubre información detallada sobre diferentes razas de perros."
  },
  it: {
      title: "Informazioni sulla Razza del Cane",
      description: "Scopri informazioni dettagliate su diverse razze di cani."
  },
  de: {
      title: "Hunderasse-Information",
      description: "Entdecke detaillierte Informationen zu verschiedenen Hunderassen."
  },
  tr: {
      title: "Köpek Irkı Bilgisi",
      description: "Farklı köpek ırkları hakkında detaylı bilgi edin."
  },
  fr: {
      title: "Informations sur la Race de Chien",
      description: "Découvre des informations détaillées sur différentes races de chiens."
  }
  // Puedes seguir agregando más idiomas según sea necesario
};

  const rotationValue = useRef(new Animated.Value(0)).current;
  const rotateIcon = () => {
    // Inicia la animación desde el valor actual hasta 360 grados (una vuelta completa)
    Animated.timing(rotationValue, {
      toValue: 1,
      duration: 500, // Duración de la animación en milisegundos
      useNativeDriver: true,
    }).start(() => {
      // Reinicia el valor de la animación para futuras animaciones
      rotationValue.setValue(0);
    });
  
    scrollToSettings(); // Mantenemos la función original
  };

  const scrollToSettings = () => {
    if (scrollViewRef.current) {
      if (isAtSettings) {
        scrollViewRef.current.scrollTo({ y: 0, animated: true });
        setIsAtSettings(false);
      } else {
        scrollViewRef.current.scrollTo({ y: 850, animated: true });
        setIsAtSettings(true);
      }
    }
  };
  
  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
      headerTitle: () => (
  
        <View style={styles.titleContainer}>
        <Image source={{ uri: 'https://app.hundezonen.ch/docs/peerrdddo.png' }} style={styles.image} />
        {/* Aquí se hace el cambio: */}
        <TypingText style={styles.title} text="Hundezonen Schweiz " />
      </View>
    ),
    headerTitleAlign: 'left',
    headerStyle: {
      backgroundColor: isNightMode ? '#333' : '#fff',
    },
    headerTintColor: isNightMode ? '#fff' : '#009688',
      headerRight: () => (
        <TouchableHighlight 
        underlayColor="rgba(255,255,255,0.1)"
          onPress={rotateIcon}
          style={{ borderRadius: 15, marginRight: 15 }}
        >
          <Animated.View style={{
            transform: [{
              rotate: rotationValue.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '360deg']
              })
            }]
          }}>
            <AntDesign name="setting" size={0} color={isNightMode ? '#009688' : '#009688'} />
          </Animated.View>
        </TouchableHighlight>
      )
    });
  }, [navigation, isNightMode, isAtSettings]);  // Mantuve isAtSettings en la lista de dependencias


  const openPrivacyPolicyLink = () => {
    Linking.openURL('https://www.example.com/privacy-policy');
  };

  const openGDPRLink = () => {
    Linking.openURL('https://www.example.com/gdpr');
  };

  const openSupportEmail = async () => {
    try {
      const url = 'mailto:info@lweb.ch';
      const supported = await Linking.canOpenURL(url);
      
      if (supported) {
        await Linking.openURL(url);
      } else {
        console.log("No se pudo abrir el cliente de correo electrónico.");
      }
    } catch (error) {
      console.error("Error al intentar enviar el correo electrónico:", error);
    }
  };


  useEffect(() => {
    const loadStoredData = async () => {
      const storedTemperature = await AsyncStorage.getItem('temperature');
      if (storedTemperature) {
        setTemperature(parseFloat(storedTemperature));
      }
      
      const storedResponseMode = await AsyncStorage.getItem('responseMode');
      if (storedResponseMode) {
        setResponseMode(storedResponseMode);
      }
    };

    loadStoredData();
  }, []);

  const clickerTrainingDescriptions = {
    en: {
      title: "Clicker Training",
      description: "Learn to train your dog using a clicker!"
    },
    es: {
      title: "Entrenamiento con Clicker",
      description: "¡Aprende a entrenar a tu perro usando un clicker!"
    },
    de: {
      title: "Clicker-Training",
      description: "Lernen Sie, Ihren Hund mit einem Clicker zu trainieren!"
    },
    fr: {
      title: "Formation au Clicker",
      description: "Apprenez à dresser votre chien avec un clicker!"
    },
    it: {
      title: "Formazione con Clicker",
      description: "Impara a addestrare il tuo cane usando un clicker!"
    },
    tr: {
      title: "Tıklamacı Eğitimi",
      description: "Bir tıklamacı kullanarak köpeğinizi nasıl eğiteceğinizi öğrenin!"
    }
  };
  

  const imageGeneratorDescriptions = {
    en: {
        title: "Image Generator",
        description: "Create images with incredible effects just with text!"
    },
    es: {
        title: "Generador de Imágenes",
        description: "Crea imágenes con efectos increíbles solo con texto!"
    },
    de: {
        title: "Bildgenerator",
        description: "Erstelle Bilder mit unglaublichen Effekten nur mit Text!"
    },
    fr: {
        title: "Générateur d'Images",
        description: "Créez des images avec des effets incroyables rien qu'avec du texte!"
    },
    it: {
        title: "Generatore di Immagini",
        description: "Crea immagini con effetti incredibili solo con del testo!"
    },
    tr: {
        title: "Resim Oluşturucu",
        description: "Sadece metinle inanılmaz etkilerle resimler oluşturun!"
    }
};


  const increaseTemperature = () => {
    if (temperature < 1.0) {
      const newTemperature = temperature + 0.1;
      setTemperature(newTemperature);
      AsyncStorage.setItem('temperature', newTemperature.toString());

      if (vibrationEnabled) {
        Vibration.vibrate(100);
      }
    }
  };

  const decreaseTemperature = () => {
    if (temperature > 0.1) {
      const newTemperature = temperature - 0.1;
      setTemperature(newTemperature);
      AsyncStorage.setItem('temperature', newTemperature.toString());

      if (vibrationEnabled) {
        Vibration.vibrate(100);
      }
    }
  };

  const changeResponseMode = () => {
    let nextResponseMode;
    switch (responseMode) {
      case 'general':
        nextResponseMode = 'veterinarian';
        break;
      case 'veterinarian':
        nextResponseMode = 'nutrición';
        break;
      case 'nutrición':
        nextResponseMode = 'training';
        break;
      case 'training':
      default:
        nextResponseMode = 'general';
        break;
    }
    
    setResponseMode(nextResponseMode);
    AsyncStorage.setItem('responseMode', nextResponseMode);

    // Llama a la función callback pasada desde App.js
    if (onResponseModeChange) {
       onResponseModeChange(nextResponseMode);
    }

    // Asegúrate de que la vibración esté habilitada antes de llamar a Vibration.vibrate()
    if (vibrationEnabled) {
      Vibration.vibrate(100);
    }
};



  const renderSection = (iconName, title, onPress) => (
    <TouchableOpacity style={[styles.section, isNightMode && styles.nightModeSection]} onPress={onPress}>
      <AntDesign name={iconName} size={25} color={isNightMode ? '#fff' : '#000'} />
      <Text style={[styles.sectionText, isNightMode && styles.nightModeText]}>{title}</Text>
    </TouchableOpacity>
  );

  const renderButton = (title, value, onValueChange) => (
    <View style={[styles.buttonContainer, isNightMode && styles.nightModeButtonContainer]}>
      <Text style={[styles.buttonText, isNightMode && styles.nightModeText]}>{title}</Text>
      <Switch value={value} onValueChange={onValueChange} thumbColor={isNightMode ? "#009688" : "#fff"} trackColor={{false: "#767577", true: "#81b0ff"}} />
    </View>
  );

  const openImageModal = () => {
    setImageModalVisible(true);
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
  };



  const renderBreedSection = () => {
    const currentBreedDescription = breedDescriptions[currentLanguage] || breedDescriptions['en'];
    const currentBreedTitle = currentBreedDescription.title;
    const [isDogBreedModalVisible, setIsDogBreedModalVisible] = useState(false);
    return (
        <TouchableOpacity onPress={() => setBreedModalVisible(true)}>
            <View style={[styles.breedSectionContainer, isNightMode && styles.nightModeBreedSectionContainer]}>
                <Image
                    style={styles.sectionImage}
                    source={{ uri: 'https://app.hundezonen.ch/docs/dog1.jpg' }}  // Cambia esto a la URL de tu imagen del breed
                />
                <Text style={[styles.sectionTitle, isNightMode && styles.nightModeSectionTitle]}>
                    {currentBreedTitle}
                </Text>
                <Text style={[styles.sectionDescription, isNightMode && styles.nightModeSectionDescription]}>
                    {currentBreedDescription.description}
                </Text>
            </View>
        </TouchableOpacity>
    );
};



  const renderImageSection = () => {
    const currentImageGeneratorDescription = imageGeneratorDescriptions[currentLanguage] || imageGeneratorDescriptions['en'];
    const currentImageGeneratorTitle = currentImageGeneratorDescription.title;

    return (
        <TouchableOpacity onPress={openImageModal}>
            <View style={[styles.imageSection, isNightMode && styles.nightModeImageSection]}>
                <Image
                    style={styles.sectionImage}
                    source={{ uri: 'https://app.hundezonen.ch/docs/res.png' }}
                />
                <Text style={[styles.sectionTitle, isNightMode && styles.nightModeSectionTitle]}>
                    {currentImageGeneratorTitle}
                </Text>
                <Text style={[styles.sectionDescription, isNightMode && styles.nightModeSectionDescription]}>
                    {currentImageGeneratorDescription.description}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

const [isClickerTrainingModalVisible, setClickerTrainingModalVisible] = useState(false);

const renderClickerTrainingSection = () => {
  const currentClickerTrainingDescription = clickerTrainingDescriptions[currentLanguage] || clickerTrainingDescriptions['en'];
  const currentClickerTrainingTitle = currentClickerTrainingDescription.title;

  return (
    <TouchableOpacity onPress={() => setClickerTrainingModalVisible(true)}>
      <View style={[styles.imageSection, isNightMode && styles.nightModeImageSection]}>
        <Image
          style={styles.sectionImage}
          source={{ uri: 'https://app.hundezonen.ch/docs/dog1%20copia.jpg' }}  // Cambia esto a la URL de tu imagen del clicker
        />
        <Text style={[styles.sectionTitle, isNightMode && styles.nightModeSectionTitle]}>
          {currentClickerTrainingTitle}
        </Text>
        <Text style={[styles.sectionDescription, isNightMode && styles.nightModeSectionDescription]}>
          {currentClickerTrainingDescription.description}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const [isSettingsExpanded, setIsSettingsExpanded] = useState(true);
const scrollViewRef = useRef(null);
const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

return (

  <ScrollView 
  ref={scrollViewRef} 
    
  contentContainerStyle={[styles.scrollView, isNightMode && styles.nightModeScrollView]}>
    <View style={[styles.container, isNightMode && styles.nightModeContainer]}>
    {renderBreedSection()}
    {renderClickerTrainingSection()} 

   

    <Modal
  animationType="slide"
  transparent={false}
  visible={isClickerTrainingModalVisible}
  onRequestClose={() => setClickerTrainingModalVisible(false)}
>
  <View style={{ flex: 1 }}>
    <TouchableOpacity
      style={[styles.closeButtonContainer, styles.closeButtonTop, isNightMode && styles.nightModeCloseButtonContainer]}
      onPress={() => setClickerTrainingModalVisible(false)}
    >
      <Icon name="arrow-down-circle-outline" size={30} color={isNightMode ? 'white' : '#000'} />
    </TouchableOpacity>
    <ClickerTraining isNightMode={isNightMode} selectedLanguage={selectedLanguage} />
  </View>
</Modal>

<Modal
  animationType="slide"
  transparent={false}
  visible={isBreedModalVisible}
  onRequestClose={() => setBreedModalVisible(false)}
>
  <View style={{ flex: 1, backgroundColor: isNightMode ? '#333' : 'white' }}>
    <TouchableOpacity
      style={[styles.closeButtonContainer, styles.closeButtonTop, isNightMode && styles.nightModeCloseButtonContainer]}
      onPress={() => setBreedModalVisible(false)}
    >
      <Icon name="arrow-down-circle-outline" size={30} color={isNightMode ? 'white' : '#000'} />
    </TouchableOpacity>
    <BreedModal 
      isNightMode={isNightMode} 
      selectedLanguage={selectedLanguage} 
      onClose={() => setBreedModalVisible(false)}
    />
  </View>
</Modal>

      {isSettingsExpanded && (
        <>
          <View style={styles.responseModeContainer}>
  <Text style={[styles.pickerLabel, isNightMode && styles.nightModeText]}>{settingsDescriptions[currentLanguage].responseMode}</Text>
  <View style={styles.tabsContainer}>
    {['general', 'veterinarian', 'nutrición', 'training'].map((mode) => (
      <TouchableOpacity 
        key={mode}
        style={[
          styles.tab, 
          responseMode === mode && styles.activeTab,
          isNightMode && styles.nightModeTab,
          responseMode === mode && isNightMode && styles.nightModeActiveTab
        ]}
        onPress={() => {
          setResponseMode(mode);
          AsyncStorage.setItem('responseMode', mode);
          if (onResponseModeChange) {
            onResponseModeChange(mode);
          }
        }}
      >
        <Text style={[
          styles.tabText, 
          responseMode === mode && styles.activeTabText,
          isNightMode && styles.nightModeTabText,
          responseMode === mode && isNightMode && styles.nightModeActiveTabText
        ]}>
          {settingsDescriptions[currentLanguage][mode]}
        </Text>
      </TouchableOpacity>
    ))}
  </View>
</View>


        <View style={styles.temperatureContainer}>
        <Text style={[styles.pickerLabel, isNightMode && styles.nightModeText]}>{settingsDescriptions[currentLanguage].botTemperature}</Text>
          <View style={styles.temperatureControls}>
            <TouchableOpacity
              style={[
                styles.tempButton,
                isNightMode && styles.nightModeTempButton,
              ]}
              onPress={decreaseTemperature}
            >
              <Text style={[styles.tempButtonText, isNightMode && styles.nightModeTempButtonText]}>
                -
              </Text>
            </TouchableOpacity>
            <TextInput
              style={[
                styles.temperatureInput,
                isNightMode && styles.nightModeInput,
                { color: isNightMode ? '#fff' : '#000' },
              ]}
              value={temperature.toFixed(1)}
              editable={false}
            />
            <TouchableOpacity
              style={[
                styles.tempButton,
                isNightMode && styles.nightModeTempButton,
              ]}
              onPress={increaseTemperature}
            >
              <Text style={[styles.tempButtonText, isNightMode && styles.nightModeTempButtonText]}>
                +
              </Text>
            </TouchableOpacity>
          </View>
        </View>
  
       
        {renderSection("message1", settingsDescriptions[currentLanguage].newChat, onCreateNewChat)}
        {renderSection("earth", settingsDescriptions[currentLanguage].changeLanguage, showLanguageModal)}

        
         
        <View style={styles.linksContainer}>

    </View>
            <ImageGeneratorModal isVisible={imageModalVisible} onClose={closeImageModal} isNightMode={isNightMode} />
          </>
        )}
        
      </View>
    </ScrollView>
    
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    
  },
  nightModeScrollView: {
    backgroundColor: '#333',
  },
  container: {
    alignItems: 'center',
    padding: 30,
  },
  nightModeContainer: {
    backgroundColor: '#333',

  },
  header: {
    fontSize: 20,
    marginBottom: 20,
    borderTopWidth: 1,
    borderTopColor: '#f5f5dc00',
  },
  nightModeText: {
    color: '#fff',
  },
  temperatureContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingBottom: 20, // Agregado para más espacio en la parte inferior
  },
  temperatureControls: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  temperatureInput: {
    width: 50,
    textAlign: 'center',
    fontSize: 18,
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
    marginHorizontal: 10,
  },
  nightModeInput: {
    borderColor: '#fff',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 12,
    marginBottom: 10,
    backgroundColor: '#d6d6d6',
    borderRadius: 20,
  },
  nightModeButtonContainer: {
    backgroundColor: '#555',
    

  },
  buttonText: {
    color: '#000',
    fontSize: 17,
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 10,
    marginBottom: 10,
    backgroundColor: '#d6d6d6',
    borderRadius: 80,
    marginTop: 10
  },
  nightModeSection: {
    backgroundColor: '#555',
  },
  sectionText: {
    color: '#05413b',
    fontSize: 18,
    marginLeft: 10,
  },
  pickerLabel: {
    marginRight: 10,
    color: '#000',
  },
  tempButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#b1b1b19c',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  nightModeTempButton: {
    backgroundColor: '#b1b1b19c',
  },
  tempButtonText: {
    fontSize: 20,
    color: '#fff',
  },
  responseModeContainer: {
    alignItems: 'center', // Centra los elementos horizontalmente
    justifyContent: 'space-between', // Distribuye el espacio entre el título y las pestañas
    paddingVertical: 5, // Añade espacio vertical para evitar que los elementos estén demasiado juntos
    marginTop: 30
    
  },
  responseModeDropdown: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 8,
    
  },
  responseModeText: {
    fontSize: 16,
    marginRight: 10,
  },
  tabsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '100%', // Ocupa todo el ancho disponible
    marginBottom: 30,
  },

  tab: {
    padding: 10,
    borderRadius: 80,
    borderWidth: 1,
    borderColor: '#d1d1d100',
    backgroundColor: '#cccccc',
    marginRight: 5,
  },
  activeTab: {
    backgroundColor: '#81b0ff',
  },
  nightModeTab: {
    borderColor: '#d1d1d100',
  },
  nightModeActiveTab: {
    backgroundColor: '#81b0ff',
  },
  tabText: {
    color: '#000',
  },
  activeTabText: {
    color: 'black',
  },
  nightModeTabText: {
    color: '#595959',
  },
  nightModeActiveTabText: {
    color: 'white',
  },
   linksContainer: {
    flexDirection: 'row', // Coloca los enlaces uno al lado del otro
    justifyContent: 'space-between', // Distribución uniforme de los enlaces
    marginTop: 20,
  },
  link: {
    padding: 0,
    borderRadius: 5,
    marginRight: 10
  },
  linkText: {
    color: '#000',
  },

  nightModeLinkText: {
    color: '#fff',
  },
  imageSection: {
    backgroundColor: '#fff',  // Puedes elegir otro color si prefieres
    borderRadius: 10,         // Esquinas redondeadas
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
       width: 10,
       height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 20,
    marginTop: 20,
    alignItems: 'center', 
    height: 200,   
    justifyContent: 'center'  // Centra el contenido verticalmente

 },

 sectionImage: {
    width: '50%',            // Ocupa todo el ancho del contenedor
    height: 100,              // Altura fija, pero podrías elegir otro valor
    borderRadius: 10,         // Para hacer que las esquinas de la imagen sean redondeadas
    marginBottom: 10          // Espacio entre la imagen y el título
 },
 sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5

 },
 sectionDescription: {
    fontSize: 14,
    color: 'gray',
    textAlign: 'center',
 },
 settingsContainer: {
  flexDirection: 'row', // para que el icono y el texto estén lado a lado
  alignItems: 'center', // para centrar verticalmente el icono y el texto
  padding: 20, // espacio interno alrededor del icono y el texto
  borderRadius: 20, // bordes redondeados
  backgroundColor: 'rgba(0,0,0,0.1)', // fondo ligeramente oscurecido
  marginTop: 20,
  marginBottom: 40,


},
breedSectionContainer: {
  width: 320,        // Ajusta el ancho de la sección según lo que necesites
  height: 200,       // Ajusta la altura de la sección según lo que necesites
  alignItems: 'center', // Centra los elementos horizontalmente
  justifyContent: 'center', // Centra los elementos verticalmente
  backgroundColor: 'rgba(255,255,255,0.1)', // para modo nocturno, un fondo ligeramente claro
  borderRadius: 10,
  bottom: 10,
  marginTop: 20

},

sectionImage: {
  width: 280,        // Ajusta el ancho de la imagen según lo que necesites
  height: 100,       // Ajusta la altura de la imagen según lo que necesites
  borderRadius: 10,
},
sectionTitle: {
  fontSize: 20,      // Ajusta el tamaño de la fuente del título según lo que necesites
  fontWeight: 'bold',
  marginTop: 10,     // Añade un margen superior para separar el título de la imagen
},
sectionDescription: {
  fontSize: 14,      // Ajusta el tamaño de la fuente de la descripción según lo que necesites
  marginTop: 5,      // Añade un margen superior para separar la descripción del título
  textAlign: 'center', // Centra el texto horizontalmente
},

nightModeSettingsContainer: {
  backgroundColor: 'rgba(255,255,255,0.1)', // para modo nocturno, un fondo ligeramente claro
},
settingsText: {
  marginLeft: 10, // un poco de espacio entre el icono y el texto
  fontSize: 16,
},
nightModeSettingsText: {
  color: '#fff',
},
nightModeImageSection: {
  backgroundColor: 'rgba(255,255,255,0.1)', // para modo nocturno, un fondo ligeramente claro
},
nightModeSectionTitle: {
  color: '#fff', // Color de texto blanco para el título en modo oscuro
},
nightModeSectionDescription: {
  color: '#ccc', // Color de texto más claro para la descripción en modo oscuro
},
closeButtonContainer: {
  marginTop: 0,

  // Otros estilos para el botón en modo claro si es necesario
},

closeButtonContainer: {
  position: 'absolute',
  top: 40, // Ajusta esta posición según sea necesario
  left: 25, // Ajusta esta posición según sea necesario
  zIndex: 1,
},
closeButtonTop: {
  top: 10, // Ajusta esta posición según sea necesario para dar espacio al icono
},
titleContainer: {
  flexDirection: 'row', // Alinea los elementos en una fila
  alignItems: 'center', // Centra los elementos verticalmente
  marginLeft: 10, // Agrega un margen izquierdo para separar la imagen del título
},

title: {
  fontSize: 18, // Tamaño de fuente para el título
  marginLeft: 10, // Margen izquierdo para separar el título de la imagen
  color: '#009688', // Color verde
},
image: {
  width: 20, // Ancho de la imagen
  height: 20, // Alto de la imagen
},
chip: {
  paddingVertical: 5,
  paddingHorizontal: 10,
  borderRadius: 20,
  backgroundColor: '#E0E0E0', // Color de fondo gris claro por defecto
  marginRight: 10, // Espacio entre chips
  top:20,
  marginBottom: 20
},
chipText: {
  color: '#000', // Color de texto por defecto
  fontSize: 12,
},
nightModeChip: {
  backgroundColor: '#d1d1d142', // Color de fondo oscuro para el modo nocturno
},
nightModeChipText: {
  color: '#FFF', // Color de texto blanco para el modo nocturno
}

});

export default SettingsScreen;
