import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Image,
  Button,
  StyleSheet,
  ScrollView,
} from 'react-native';
import axios from 'axios';
import Spinner from 'react-native-loading-spinner-overlay';
import Icon from 'react-native-vector-icons/Ionicons';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import * as Localization from 'expo-localization'; // Asegúrate de instalar esta librería
import AsyncStorage from '@react-native-async-storage/async-storage'; // Importar AsyncStorage

const ImageGeneratorModal = ({ isVisible, onClose, isNightMode, selectedLanguage }) => {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEffect, setSelectedEffect] = useState('none');
  const scrollViewRef = useRef(null);
  const [language, setLanguage] = useState(Localization.locale.split('-')[0]);
  const [generatedImagesCount, setGeneratedImagesCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const titles = {
    en: "Generate Image",
    es: "Generar Imagen",
    fr: "Générer l'Image",
    de: "Bild Generieren",
  };

  const placeholders = {
    en: "Enter a prompt",
    es: "Introduce un indicador",
    fr: "Entrez une invite",
    de: "Geben Sie einen Hinweis ein",
  };

  const currentTitle = titles[language] || titles['en'];
  const currentPlaceholder = placeholders[language] || placeholders['en'];

  useEffect(() => {
    if (selectedLanguage) {
      setLanguage(selectedLanguage);
    }

    // Cargar el contador y el tiempo restante desde AsyncStorage
    AsyncStorage.getItem('generatedImagesCount').then((count) => {
      if (count) {
        setGeneratedImagesCount(parseInt(count));
      }
    });

    AsyncStorage.getItem('timeRemaining').then((remaining) => {
      if (remaining) {
        setTimeRemaining(parseInt(remaining));
        startCountdown(); // Iniciar la cuenta regresiva si es necesario
      }
    });
  }, [selectedLanguage]);

  const saveStateToStorage = () => {
    // Guardar el contador y el tiempo restante en AsyncStorage
    AsyncStorage.setItem('generatedImagesCount', generatedImagesCount.toString());
    AsyncStorage.setItem('timeRemaining', timeRemaining.toString());
  };

  const startCountdown = () => {
    const resetTime = new Date();
    resetTime.setHours(24, 0, 0, 0); // Reinicio diario a la medianoche

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const remaining = Math.max(0, resetTime - currentTime);

      if (remaining === 0) {
        // Reiniciar el contador y permitir que el usuario genere imágenes nuevamente
        clearInterval(intervalId);
        setGeneratedImagesCount(0);
        setTimeRemaining(null);
        saveStateToStorage();
      } else {
        // Actualizar el tiempo restante en segundos
        setTimeRemaining(Math.floor(remaining / 1000));
        saveStateToStorage();
      }
    }, 1000);
  };

  const handleGenerateImage = async () => {
    if (generatedImagesCount >= 2) {
      // Mostrar un mensaje de límite alcanzado
      return;
    }

    setIsLoading(true);

    let fullPrompt = prompt;
    if (selectedEffect !== 'none') {
      fullPrompt += ` with a ${selectedEffect} effect`;
    }

    try {
      const apiUrl = 'https://api.openai.com/v1/images/generations';
      const response = await axios.post(
        apiUrl,
        {
          prompt: fullPrompt,
          n: 1,
          size: '512x512',
        },
        {
          headers: {
            Authorization: 'Bearer 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',
            'Content-Type': 'application/json',
          },
        }
      );
      setImageUrl(response.data.data[0].url);

      // Actualizar el contador de imágenes generadas
      setGeneratedImagesCount((prevCount) => prevCount + 1);
      saveStateToStorage();

      // Agregar un retraso de 2 segundos antes de ocultar el loader
      setTimeout(() => {
        setIsLoading(false);
        scrollViewRef.current.scrollToEnd({ animated: true });
      }, 2000);
    } catch (error) {
      console.error('Error generating image:', error);
      setIsLoading(false); // En caso de error, ocultar el loader inmediatamente
    }
  };


  const shareImage = async () => {
    try {
      // Verificar la disponibilidad de compartir
      if (!(await Sharing.isAvailableAsync())) {
        alert("¡La opción de compartir no está disponible en esta plataforma!");
        return;
      }
  
      // Descargar y guardar la imagen localmente
      const { uri } = await FileSystem.downloadAsync(
        imageUrl, 
        FileSystem.cacheDirectory + 'downloaded-image.jpg'
      );
    
      // Compartir la imagen descargada
      await Sharing.shareAsync(uri);
    } catch (error) {
      console.error("Error compartiendo la imagen:", error.message);
      alert('Hubo un problema al compartir la imagen. Por favor intenta de nuevo.');
    }
  };
  
  


  return (
    <Modal
      visible={isVisible}
      animationType="slide"
      onRequestClose={onClose}
      transparent={true}
    >
        <ScrollView
        ref={scrollViewRef}
        style={[
          isNightMode ? styles.nightModeScrollview : styles.scrollview
        ]}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <View style={[styles.container, isNightMode && styles.nightModeContainer, { marginTop: 0 }]}>
          <TouchableOpacity onPress={onClose} style={styles.backButton}>
              <Icon name="arrow-down-circle-outline" size={30} color={isNightMode ? '#fff' : '#093632a8'} />
          </TouchableOpacity>
          <Spinner visible={isLoading} textContent={'Generating...'} textStyle={styles.spinnerText} />
          <Text style={[styles.title, isNightMode && styles.nightModeTitle]}>
            {currentTitle}
          </Text>
          <TextInput
            style={[styles.input, isNightMode && styles.nightModeInput]}
            onChangeText={(text) => setPrompt(text)}
            value={prompt}
            placeholder={currentPlaceholder}
            placeholderTextColor={isNightMode ? '#aaa' : '#888'}
        />
        <ScrollView horizontal={true} style={styles.effectsContainer}>
          <TouchableOpacity
            style={[
              styles.effectButton,
              selectedEffect === 'none' && styles.selectedEffectButton,
            ]}
            onPress={() => setSelectedEffect('none')}
          >
            <Image source={{ uri: 'https://app.hundezonen.ch/docs/res4.png' }} style={styles.effectImage} />
            <Text style={styles.effectText}>No Effect</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.effectButton,
              selectedEffect === 'psychedelic' && styles.selectedEffectButton,
            ]}
            onPress={() => setSelectedEffect('psychedelic')}
          >
            <Image source={{ uri: 'https://app.hundezonen.ch/docs/res.png' }} style={styles.effectImage} />
            <Text style={styles.effectText}>Psychedelic</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.effectButton,
              selectedEffect === 'watercolor' && styles.selectedEffectButton,
            ]}
            onPress={() => setSelectedEffect('watercolor')}
          >
            <Image source={{ uri: 'https://app.hundezonen.ch/docs/res5.png' }} style={styles.effectImage} />
            <Text style={styles.effectText}>Watercolor</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.effectButton,
              selectedEffect === 'pencil' && styles.selectedEffectButton,
            ]}
            onPress={() => setSelectedEffect('pencil')}
          >
            <Image source={{ uri: 'https://app.hundezonen.ch/docs/res3.png' }} style={styles.effectImage} />
            <Text style={styles.effectText}>Pencil</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.effectButton,
              selectedEffect === 'neon' && styles.selectedEffectButton,
            ]}
            onPress={() => setSelectedEffect('neon')}
          >
            <Image source={{ uri: 'https://app.hundezonen.ch/docs/res1.png' }} style={styles.effectImage} />
            <Text style={styles.effectText}>Neon</Text>
          </TouchableOpacity>
        </ScrollView>
        <Button title="Generate" onPress={handleGenerateImage} />
        {imageUrl ? (
  <TouchableOpacity 
    onPress={shareImage} 
    style={{ backgroundColor: '#8686865c', width: '100%', height: 340, justifyContent: 'center', alignItems: 'center', borderRadius: 10, position: 'relative' }}
  >
    <Image source={{ uri: imageUrl }} style={styles.image} />
    <View style={{ position: 'absolute', zIndex: 2, backgroundColor: '#0000009e', borderRadius: 50, padding: 5 }}>
      <Icon name="download-outline" size={21} color="white" />
    </View>
  </TouchableOpacity>
) : null}
      </View>
      </ScrollView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#093632a8',
    top: 20,
  },
  nightModeTitle: {
    color: '#fff',
  },
  input: {
    height: 40,
    width: '90%',
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 80,
    paddingHorizontal: 15,
    fontSize: 16,
    backgroundColor: '#fff',

  },
  nightModeInput: {
    borderColor: '#444',
    backgroundColor: '#222',
    color: '#fff',
  },
  image: {
    width: '100%',
    height: 300,
    resizeMode: 'contain',
    marginTop: 0,
    borderRadius: 10,

  },
  downloadButton: {
    marginTop: 10,
    backgroundColor: 'black',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
    marginBottom: 10,


  },
  downloadButtonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  closeButton: {
    marginTop: 10,
  },
  closeButtonText: {
    color: '#333',
    fontSize: 18,

  },
  nightModeCloseButtonText: {
    color: '#fff',
  },
  spinnerText: {
    color: '#FFF',
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
    zIndex: 999, // Asegura que el icono esté en la parte superior
  },
  picker: {
    width: '100%',
    height: 50,
    marginBottom: 16, // Añade un margen en la parte inferior para separar el selector del botón
  },
  nightModePicker: {
    backgroundColor: '#222',
    color: '#fff',
  },
  imageContainer: {
    marginTop: 16, // Añade un margen en la parte superior para separar la imagen del botón
  },
  scrollview: {
    flex: 1,
  },
  imageScroll: {
    marginTop: 16,
  },
    container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 60,

  },
  nightModeContainer: {
    backgroundColor: '#343434',
  },
  effectsContainer: {
    marginTop: 20,
    marginBottom: 20, // Espacio entre las miniaturas y otros elementos
  },
  effectButton: {
    alignItems: 'center',
  },

  selectedEffectButton: {
    borderColor: '#007bff', // You can customize the selected effect's style
    borderWidth: 2,
    borderRadius: 10,
    padding: 5
  },

  effectImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  effectText: {
    marginTop: 5,
    color: '#007bff',
    fontWeight: 'bold',
  },
  scrollview: {
    flex: 1,
    marginTop: 10,
    backgroundColor: '#d1d1d1c7',
  },

nightModeScrollview: {
    flex: 1,
    marginTop: 10,
    backgroundColor: '#464646b0', // Apply night mode background color
  },
  modal: {
    backgroundColor: '#aeaeae', // Adjust this as needed
   
  },
  nightModeModal: {
    backgroundColor: '#aeaeaec4', // Night mode background color
  },

  effectButton: {
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10, // Espacio entre las miniaturas
  },


});

export default ImageGeneratorModal;